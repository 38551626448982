import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public myDateFormate(date, fromFormate, toFormate) {
    let dateString = date;
    let momentVariable = moment(dateString, fromFormate);
    let stringvalue = momentVariable.format(toFormate);
    console.log('stringvalue', stringvalue); // outputs 2020-05-02
    return stringvalue;
  }

  static getRootFontValue() {
    const width = window.innerWidth;
    switch (true) {
      case width <= 768:
        return 1.6;
      case width <= 892:
        return 1.4;
      case width <= 992:
        return 1.3;
      case width <= 1200:
        return 1.2;
      case width <= 1366:
        return 1;
      case width <= 1536:
        return 0.9;
      case width <= 1920:
        return 0.75;
      default:
        return 1;
    }
  }

  static adjustSize(target: number, baseScreenSize: number = 2560) {
    return Math.round((target / baseScreenSize) * window.innerWidth * this.getRootFontValue());
  }

  static generateRandomNumberBetween(min: number, max: number) {
    if (!min || !max) return;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static formatNumber(num: number) {
    if (!num) return;
    return `${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
