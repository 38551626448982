import { AuthGuard } from './@core/auth/_guards/auth.guard';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
// Auth

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('app/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('app/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/pages/pages.module').then((m) => m.PagesModule),
    data: { breadcrumb: 'Home' },
  },
  { path: '**', redirectTo: 'pages', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
