import { Injectable } from '@angular/core';
import { API_URL, BASE_URL } from '../../@core/constants/api';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertsService } from './alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  private api_url: String = API_URL;
  private _isHeaderPresented: BehaviorSubject<string> = new BehaviorSubject(null);
  private _openSideNav: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isHeaderPresented$: Observable<string> = this._isHeaderPresented.asObservable();

  private audienceId = new BehaviorSubject('');
  audienceIdObser = this.audienceId.asObservable();

  changeAudienceIdObser(id: any) {
    this.audienceId.next(id);
  }

  constructor(private httpClient: HttpClient, private alertsService: AlertsService, private router: Router) {}

  toggleHeader(value: string) {
    this._isHeaderPresented.next(value);
  }
  toggleSideNav(value: boolean) {
    this._openSideNav.next(value);
  }

  get(url, filters?): Observable<any> {
    if (filters) {
      return this.httpClient.get(`${this.api_url}/${url}`, { params: filters });
    } else {
      return this.httpClient.get(`${this.api_url}/${url}`);
    }
  }

  downloadFileAsBlob(url): any {
    return this.httpClient.get(`${this.api_url}/${url}`, {
      responseType: 'blob',
    });
  }

  post(url, payload): Observable<any> {
    return this.httpClient.post(`${this.api_url}/${url}`, payload);
  }

  postFile(url, payload): Observable<any> {
    return this.httpClient.post(`${this.api_url}/${url}`, payload, {
      responseType: 'blob',
    });
  }

  put(url, payload): Observable<any> {
    return this.httpClient.put(`${this.api_url}/${url}`, payload);
  }

  patch(url, payload): Observable<any> {
    return this.httpClient.patch(`${this.api_url}/${url}`, payload);
  }

  delete(url): Observable<any> {
    return this.httpClient.delete(`${this.api_url}/${url}`);
  }

  downloadFile(url): any {
    return this.httpClient.get(`${this.api_url}/${url}`, {
      responseType: 'blob',
    });
  }

  getfullUrl(url) {
    return this.httpClient.get(url);
  }
  checkPermissions(payload): any {
    return this.httpClient.post(`${this.api_url}/users/permissions/check`, payload);
  }

  handleSuccess(res, message = null) {
    if (res?.data?.message) {
      this.alertsService.topMixin.fire({
        icon: 'success',
        title: res?.data?.message,
        timer: 2000,
        width: '300px',
      });
    } else {
      this.alertsService.topMixin.fire({
        icon: 'success',
        title: res,
        timer: 2000,
        width: '300px',
      });
    }
  }

  handleError(err) {
    if (err?.status == 422) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: err?.error?.message,
        timer: 2000,
        width: '300px',
      });
    } else if (err?.status == 500) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: 'Internal Server Error',
        timer: 2000,
        width: '300px',
      });
    } else if (err?.status == 400) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: err?.error?.data?.message,
        timer: 2000,
        width: '300px',
      });
    } else if (err?.status == 404) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: err?.error?.data?.message,
        timer: 2000,
        width: '300px',
      });
    } else if (err?.status == 401) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: err?.error?.data?.error,
        timer: 2000,
        width: '300px',
      });
    } else if (err?.status == 0) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: 'No Internet Connection',
        timer: 5000,
        width: '300px',
      });
    } else if (err?.message?.includes('mismatch')) {
      this.router.navigate(['./']);
    } else if (err) {
      this.alertsService.topMixin.fire({
        icon: 'error',
        title: 'Error',
        timer: 5000,
        width: '300px',
      });
    }
  }

  reportBug(payload) {
    return this.httpClient.post(`${this.api_url}/bugs`, payload);
  }
}
