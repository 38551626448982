export const locale = {
  lang: 'en',
  data: {
    home: {
      users: {
        title: 'Title',
        subtitle: 'Håndter brugere',
      },
      customers: {
        title: 'Kunder',
        subtitle: 'Vis og håndter kunder',
      },
      deals: {
        title: 'Leasingtilbud',
        subtitle: 'Vis og håndter tilbud',
      },
      contracts: {
        title: 'Kontrakter',
        subtitle: 'Tilføj, vis og håndter kontrakter',
      },
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      USERS: 'Brugere',
      CUSTOMERS: 'Kunder',
      OFFERS: 'Leasingtilbud',
      CONTRACTS: 'Kontrakter',
      ORGANIZATIONS: 'Firmaer',
      ROLES: 'Rolle',
      TYPES: 'Køretøjstyper',
      MAKES: 'Køretøjsmærker',
      VEHICLES: 'Køretøjer',
      TASKS: 'Opgaver',
      PRODUCTS: 'Produkter',
      PAYMENT_TERMS: 'Betalingsbetingelser',
      TRANSACTION_TYPES: 'Transaktionstyper',
      TRANSACTIONS: 'Betalinger',
      CONTRACTTRANSACTION: 'Transaktioner',
      ECONOMICS: 'Economic posteringer',
      AMORTIZATION: 'Amortisering',
      SETTINGS: 'Indstillinger',
      CHANGE_CODE: 'Skift kode',
      LOGOUT: 'log ud',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        HERE: 'her',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_PASSWORD: 'Glemt Kode?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login to your account',
        EMAIL: 'Email address',
        PASSWORD: 'Password',
        REMEMBER_ME: 'Husk kode',
        BUTTON: 'Login',
        FOOTER_TXT1: 'Intet problem. Klik',
        FOOTER_TXT2: 'for at nulstille dit kodeord.',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        FORGOT_BUTTON: 'Send password reset link',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      RESET_PASSWORD: {
        TITLE: 'Reset Password',
        DESC: 'Enter your details to reset your password',
        SUCCESS: 'Your password has been successfuly reset.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    SUBHEADER: {
      TITLE: 'Recent Bookings',
      SUB_TITLE: 'Onling Bookings Management',
      FIELDS: {
        BOOKING_NUMBER: 'Booking Number',
        FROM: 'From',
        TO: 'To',
        SEARCH_BOOKINGS: 'Search Bookings',
      },
    },
    USER_PROFILE: {
      TITLE: 'User profile',
      CHANGE_PASSWORD: {
        TITLE: 'Skift koden',
        FIELDS: {
          OLD_PASSWORD: {
            NAME: 'Please enter <strong> Nuværende kode</strong>',
            PLACEHOLDER: 'Nuværende kode',
            ERROR_MSG: 'Nuværende kode skal udfyldes.',
          },
          NEW_PASSWORD: {
            NAME: 'Please enter <strong> Ny kode</strong>',
            PLACEHOLDER: 'Ny kode',
            ERROR_MSG: 'Kode skal udfyldes.',
          },
          NEW_PASSWORD_CONFIRMATION: {
            NAME: 'Please enter <strong> Bekræft ny kode</strong>',
            PLACEHOLDER: 'Bekræft ny kode',
            ERROR_MSG: 'Bekræft ny kode skal udfyldes.',
          },
        },
      },
    },
    USERS: {
      view: {
        title: 'Users',
        buttons: {
          add: 'Add User',
          edit: 'Edit',
        },
        datatable: {
          name: 'Name',
          email: 'Email',
          activeNotActive: 'Active/Inactive',
          title: 'Title',
          edit: 'edit',
          delete: 'delete',
          pauseAndActivate: 'pause / activate',
        },
      },
      add: {
        addTitle: 'Add User',
        editTitle: 'Edit User',
        organizations: 'Employee',
        userInfo: 'User Info',
        fields: {
          company: {
            name: 'TCM',
            placeholder: 'Vælg firma...',
          },
          email: {
            name: 'Email',
            placeholder: 'Email address',
          },
          name: {
            name: 'name ',
            placeholder: 'Name',
          },
          role: {
            name: 'Role',
            placeholder: '',
          },
          active: {
            name: 'Active',
            placeholder: '',
          },
          password: {
            name: 'Password',
            placeholder: '',
          },
          confirmPassword: {
            name: 'Confirm Password',
            placeholder: '',
          },
          profilePicture: {
            name: 'Profile Picture',
            placeholder: '',
          },
        },
        actionButtons: {
          addSubmit: 'Submit',
          editSubmit: 'Confirm',
          cancel: 'Cancel',
        },
      },
    },
    LOOKUPS: {
      VIEW: {
        TITLE: 'Lookups',
        HEAD_TITLE: 'Manage lookups',
        NEW_BUTTON: 'New lookup',
        NEW_BUTTON_TOOLTIP: 'Create new lookup',
        EDIT_BUTTON: 'Edit lookup',
        DELETE_BUTTON: 'Delete lookup',
        TABLE_COLUMNS: {
          ID: 'ID',
          NAME: 'Name',
          CATEGORY: 'Category',
          VALUE: 'Value',
          CREATED: 'Created at',
          UPDATED: 'Updated at',
        },
        DELETE_MODAL: {
          TITLE: 'Lookup Delete',
          DESCRIPTION: 'Are you sure to permanently delete this lookup?',
          WAIT_DESCRIPTION: 'Lookup is deleting...',
        },
      },
      ADD: {
        ADD_TITLE: 'Create new lookup',
        EDIT_TITLE: 'Edit lookup',
        FIELDS: {
          NAME: {
            PLACEHOLDER: 'Name',
            ERROR_MSG: 'Name er <strong>required</strong>',
          },
          CATEGORY: {
            PLACEHOLDER: 'Category',
            ERROR_MSG: 'Category is <strong>required</strong>',
          },
          VALUE: {
            PLACEHOLDER: 'Value',
            ERROR_MSG: 'Value is <strong>required</strong>',
          },
        },
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    DASHBOARD: {
      USERS_DESC: 'Håndter brugere',
      CUSTOMERS_DESC: 'Vis og håndter kunder',
      OFFERS_DESC: 'Vis og håndter tilbud',
      CONTRACTS_DESC: 'Tilføj, vis og håndter kontrakter',
      home: {
        users: {
          title: 'Brugere',
          subtitle: 'Håndter brugere',
        },
        customers: {
          title: 'Kunder',
          subtitle: 'Vis og håndter kunder',
        },
        deals: {
          title: 'Leasingtilbud',
          subtitle: 'Vis og håndter tilbud',
        },
        contracts: {
          title: 'Kontrakter',
          subtitle: 'Tilføj, vis og håndter kontrakter',
        },
      },
      topNav: {
        reportBugButton: 'Indrapportér problem',
        dotsNavigationMenu: {
          users: 'Brugere',
          customers: 'Kunder',
          deals: 'Leasingtilbud',
          contracts: 'Kontrakter',
        },
        newOfferButton: 'Nyt leasingtilbud',
        adminMenu: {
          admin: 'Admin',
          organizations: 'Firmaer',
          roles: 'Rolle',
          vehicleTypes: 'Køretøjstyper',
          vehicleBrands: 'Køretøjsmærker',
          vehicles: 'Køretøjer',
          contractsTasks: 'Opgaver',
          products: 'Produkter',
          paymentTerms: 'Betalingsbetingelser',
          transactionTypes: 'Transaktionstyper',
          contractsPayments: 'Betalinger',
          contractsTransactions: 'Transaktioner',
          economicEntries: 'Economic posteringer',
          amortizationMatrix: 'Amortisering',
        },
        profileButton: {
          settings: 'Indstillinger',
          passwordChange: 'Skift kode',
          logout: 'Log ud',
        },
      },
      reportBugModal: {
        title: 'Indrapportér problem',
        fields: {
          title: {
            name: 'Titel:',
            placeholder: 'Titel',
          },
          urgency: {
            name: 'Prioritet:',
            placeholder: '',
            critical: 'Kritisk',
            not_critical: 'Ikke kritisk',
          },
          screenshot: {
            name: 'Skærmbillede:',
            placeholder: 'Screenshot',
          },
          message: {
            name: 'Detaljer:',
            placeholder: 'Detaljer',
          },
        },
        actionButtons: {
          submit: 'Send',
          cancel: 'Annuller',
        },
      },
      changePassword: {
        breadcrumbTitle: 'Skift koden',
        title: 'Skift koden',
        fields: {
          current_password: 'Nuværende kode',
          password: 'Ny kode',
          password_confirmation: 'Bekræft ny kode',
        },
        confirm: 'Bekræft',
      },
    },
    GENERAL: {
      CONFIRM: 'Confirm',
      FRONT_PAGE: 'Front page',
      SEARCH: 'Search:',
      CANCEL: 'Cancel',
      DELETE: 'Delete',
      BACK: 'Back',
      RESET: 'Reset',
      SAVE: 'save',
      PLEASE_WAIT: 'Please wait....',
      SOMETHING_WENT_WRONG: 'Something went wrong',
      PLEASE_ENTER: 'Please enter',
      REQUIRED: 'required',
      LOADING: 'Loading ...',
      VIS: 'Vis',
      LINES: 'lines',
      SHOWING: 'Showing',
      TO: 'to',
      OF: 'of',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      YES: 'Yes',
      NO: 'No',
      NO_RECORDS_FOUND: 'No records available.',
      TABLE_COLUMNS: {
        image: 'Image',
        name: 'Name',
        description: 'Description',
        date: 'Date',
        ACTIONS: 'Actions',
      },
      fields: {
        image: 'Image',
        dropzone: {
          heading: 'Drop files here or click to upload.',
          note: 'Only image files are allowed for upload',
        },
        description: {
          name: 'Description:',
          placeholder: 'Enter description',
        },
      },
      action_btns: {
        submit: 'Submit',
        cancel: 'Cancel',
      },
    },
    ORGANIZATIONS: {
      NEW_BUTTON: 'Add User',
      view: {
        title: 'Firmaer',
        buttons: {
          add: 'Opret nyt firma',
        },
        datatable: {
          name: 'Name',
          city: 'City',
          zipCode: 'Zip Code',
          state: 'State',
          edit: 'edit',
          delete: 'delete',
          actions: 'Actions',
        },
      },
      add: {
        addTitle: 'Opret nyt firma',
        editTitle: 'Rediger Firma',
        company: 'Firma',
        logo: 'Logo',
        fields: {
          name: {
            name: 'Navn:',
            placeholder: 'Name',
          },
          address: {
            name: 'Adresse:',
            placeholder: 'Adresse:',
          },
          zipCode: {
            name: 'Postnummer:',
            placeholder: 'Postnummer',
          },
          city: {
            name: 'By:',
            placeholder: 'By',
          },
          cvrNumber: {
            name: 'CVR nummer:',
            placeholder: 'CVR nummer',
          },
          logo: {
            name: 'Logo:',
            placeholder: '',
          },
          active: {
            name: 'Aktiv:',
            placeholder: '',
          },
          depreciationTableValue: {
            name: 'Afskrivngstabel',
          },
          contractSetupFee: {
            name: 'Contract setup fee:',
          },
        },
        actionButtons: {
          addSubmit: 'Tilføj',
          editSubmit: 'Opdater',
          cancel: 'Annuller',
          generateDepreciationTable: 'Aktivér brugerdefineret afskrivningstabel',
          returnToDepreciationTable: 'Anvend standardafskrivning',
          uploadLogo: 'Upload logo',
        },
      },
    },
    VehicleTypes: {
      view: {
        title: 'Køretøjstyper',
        buttons: {
          add: 'Add User',
        },
        datatable: {
          id: 'Id',
          vehicleType: 'Køretøjstype',
          state: 'Aktiv/Inaktiv',
          action: 'Rediger',
        },
        addEditVehicleTypeModal: {
          addTitle: 'Nyt Køretøj',
          editTitle: 'Rediger Køretøj',
          fields: {
            vehicleType: 'Køretøjstype',
            state: 'Aktiv',
          },
          actionButtons: {
            cancel: 'Annuller',
            addSubmit: 'Tilføj',
            editSubmit: 'Opdatering',
          },
        },
      },
    },
    VehicleBrands: {
      view: {
        title: 'Køretøjsmærker',
        buttons: {
          add: 'Opret nyt køretøjsmærke',
        },
        datatable: {
          id: 'Id',
          vehicleBrand: 'Køretøjsmærker',
          type: 'Type',
          action: 'Rediger',
        },
        addEditVehicleBrandModal: {
          addTitle: 'Opret nyt køretøjsmærke',
          editTitle: 'Rediger køretøjsmærke',
          fields: {
            vehicleBrand: 'Køretøjsmærke',
            type: 'Type',
          },
          actionButtons: {
            cancel: 'Annuller',
            addSubmit: 'Tilføj',
            editSubmit: 'Opdatering',
          },
        },
      },
    },
    PaymentTerms: {
      view: {
        title: 'Betalingsbetingelser',
        buttons: {
          add: 'Opret ny betalingsbetingelse',
        },
        datatable: {
          name: 'Navn',
          economicPaymentTermNumber: 'Betalingsbetingelsesnummer',
          daysOfCredit: 'Dage',
          action: 'Rediger',
        },
        addEditPaymentTermModal: {
          addTitle: 'Opret ny betalingsbetingelse',
          editTitle: 'Rediger Betalingsbetingelse',
          fields: {
            name: 'Navn',
            economicPaymentTermNumber: 'Betalingsbetingelsesnummer',
            daysOfCredit: 'Dage',
          },
          actionButtons: {
            cancel: 'Annuller',
            addSubmit: 'Tilføj',
            editSubmit: 'Opdatering',
          },
        },
      },
    },
    Vehicles: {
      view: {
        title: 'Køretøjer',
        buttons: {
          add: 'Opret nyt køretøj',
          importMarketValues: 'Importer markedsværdier',
        },
        datatable: {
          id: 'ID',
          type: 'Type',
          brand: 'Mærke',
          model: 'Model',
          serial_number: 'Stelnummer',
          date_of_first_registration: 'Første indregistrering',
        },
        importMarketValuesModal: {
          title: 'Importer markedsværdier for køretøjer',
          fields: {
            upload: 'Upload',
            cancel: 'Annuller',
            downloadTemplate: 'Download skabelon',
          },
        },
      },
      add: {
        addTitle: 'Nyt Køretøj',
        editTitle: 'Rediger Køretøj',
        fields: {
          id: {
            name: 'Id',
            placeholder: '',
          },
          type: {
            name: 'Type',
            placeholder: '',
          },
          brand: {
            name: 'Mærke',
            placeholder: '',
          },
          model: {
            name: 'Model',
            placeholder: '',
          },
          usedVat: {
            name: 'Brugtmoms',
            yes: 'Ja',
            no: 'Ingen',
          },
          engine: {
            name: 'Motor',
            placeholder: '',
          },
          mileage: {
            name: 'Kørte km',
            placeholder: '',
          },
          marketValue: {
            name: 'Markedsværdi',
            placeholder: '',
          },
          carType: {
            name: 'Biltype',
            placeholder: '',
          },
          color: {
            name: 'Farve',
            placeholder: '',
          },
          version: {
            name: 'Udstyrsvariant',
            placeholder: '',
          },
          vehicleUsedVat: {
            name: 'Er køretøj med brugtmoms/momsdød? (§25)',
            placeholder: '',
          },
          gears: {
            name: 'Antal gear',
            placeholder: '',
          },
          wheelSize: {
            name: 'Størrelse på fælge',
            placeholder: '',
          },
          wheelType: {
            name: 'Fælgtype',
            placeholder: '',
          },
          interiorColour: {
            name: 'Farve på indtræk',
            placeholder: '',
          },
          interiorType: {
            name: 'Indtrækkets stoftype',
            placeholder: '',
          },
          sunroof: {
            name: 'Soltag',
            placeholder: '',
          },
          showOnFrontPage: {
            name: 'Vis på forside',
            placeholder: '',
          },
          date_of_first_registration: {
            name: 'Første registreringsdato',
            placeholder: '',
          },
          serial_number: {
            name: 'Stelnr.',
            placeholder: '',
          },
          ekstra: {
            name: 'Ekstraudstyr',
            placeholder: '',
          },
          fuel: {
            name: 'Brændstof',
            placeholder: '',
          },
          transmission: {
            name: 'Transmission',
            placeholder: '',
          },
          description: {
            name: 'Beskrivelse',
            placeholder: '',
          },
          condition: 'Stand',
          euro_ncap: '5-stjernet NCAP markering ncap',
          abs: 'ABS',
          customs_inpsections: 'Toldsynet',
          price_as_new_cents: 'Nypris',
          km_per_litre: 'km/l',
          num_airbags: 'Antal airbags',
          num_seat_belts: 'Antal selealarmer',
          num_integrated_children_seats: 'Antal integrerede børnesæder',
        },
        actionButtons: {
          addSubmit: 'Tilføj',
          editSubmit: 'Opdatering',
          cancel: 'Annuller',
        },
      },
      details: {
        vehicle: 'KØRETØJER',
        editButtonText: 'Rediger',
        addVehicle: 'Nyt Køretøjer',
        editVehicle: 'Rediger Køretøjer',
        tabs: {
          vehicleInformation: {
            title: 'Køretøjsoplysninger',
            addMileageModal: {
              modalTitle: 'Tilføj kørte km',
              buttonText: 'Tilføj kørte km',
              fields: {
                date: 'Dato',
                mileage: 'Kørte kilometer',
              },
              actionButtons: {
                addSubmit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
            addMarketValueModal: {
              modalTitle: 'Tilføj markedsværdi',
              buttonText: 'Tilføj markedsværdi',
              fields: {
                date: 'Dato',
                marketValue: 'Køretøjets markedsværdi',
                source: 'Kilde',
                valuationType: 'Vurderingskategori',
              },
              actionButtons: {
                addSubmit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
          },
          marketValues: {
            title: 'Markedsværdier',
            datatable: {
              date: 'Dato',
              source: 'Kilde',
              market_value_cents: 'Markedsværdi',
              valuation_description: 'Værdiansættelsesbeskrivelse',
            },
          },
          mileage: {
            title: 'Kørte kilometer',
            datatable: {
              date: 'Dato',
              mileage: 'Kørte kilometer',
            },
          },
          gallery: {
            title: 'Billedgalleri',
            mainImageSection: {
              title: 'Primære køretøjsbilleder',
              mainImageUrl: 'Primært billede:',
              editImage: 'Rediger billede.',
              uploadImage: 'Upload billede.',
            },
            vehiclesGallerySection: {
              title: 'Bil Galleri',
              upload: 'Upload',
              datatable: {
                id: 'ID',
                image: 'Billede',
                created_at: 'Uploadtidspunkt',
                actions: 'Handlinger',
              },
            },
          },
          marketPriceReference: {
            title: 'Handelspris-referencer',
            addMarketPriceReferenceModal: {
              buttonText: 'Tilføj handelspris-reference',
              title: 'Tilføj handelspris-reference',
              actionButtons: {
                submit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
            datatable: {
              source_of_add: 'Kilde',
              vehicle_model: 'Mærke og model',
              date_of_first_registration: '1. Indregistrering',
              kilometer: 'Km-stand',
              price_cents: 'Pris',
              file_documents: 'Dokumentation',
            },
          },
        },
      },
    },
    ContractPayments: {
      view: {
        title: 'Betalinger',
        buttons: {
          createInvoicesByDate: 'Opret fakturaer',
          createInvoicesByIds: 'Bogfør og send valgte',
        },
        filters: {
          byDate: 'Fra dato',
          toDate: 'Til dato',
          hideBookedPayments: 'Vis ikke bogførte',
        },
        datatable: {
          contract_id: 'Kontraktnr.',
          contract_payment_id: 'Contract Payment Id',
          customer_name: 'Navn',
          product_name: 'Betalingstype',
          payment_date: 'Dato',
          text: 'Tekst',
          booked: 'Bogført',
          payment_excl_tax_vat_cents: 'Beløb i kr. ekskl. moms',
          vat_cents: 'Moms',
          payment_incl_tax_vat_cents: 'Beløb i alt',
          actions: 'Handlinger',
          delete: 'Slet',
        },
        createInvoicesModal: {
          title: 'Opret og bogfør fakturaerne for betalingerne',
          remarkNote:
            'BEMÆRK: Alle fakturaerne vil blive oprettet på den valgte bogføringsdato og med opkrævningsdatoen, der følger betalingsbetingelserne.',
          fields: {
            contract_payment_book_date: 'Betalinger til og med',
            due_date: 'Bogføringsdato',
            payment_term_number: {
              name: 'Betalingsbetingelser',
              placeholder: 'Vælg betalingsbetingelser',
            },
          },
          actionButtons: {
            cancel: 'Annuller',
            submit: 'Opret og bogfør',
          },
        },
      },
    },
    EconomicEntries: {
      view: {
        title: 'Economic posteringer',
        buttons: {
          update: 'Opdater',
        },
        datatable: {
          Id: 'Id',
          entryNumber: 'Løbenr.',
          entryType: 'Type',
          entryDate: 'Bogføringsdato',
          accountNumber: 'Konto',
          voucherNumber: 'Bilagsnr.',
          amount: 'Beløb',
          currency: 'Valuta',
          text: 'Tekst',
        },
      },
    },
    Amortization: {
      view: {
        title: 'Amortisering',
        buttons: {
          generateAmortizationMatrix: 'Dan amortiseringsmatrix',
          generateAmortizationRepayments: 'Dan afdragstransaktioner',
        },
        datatable: {
          contractPaymentId: 'Betalings Id',
          contractId: 'Kontrakt Id',
          fromDate: 'Fra dato',
          toDate: 'Til dato',
          paymentExclVAT: 'Betaling',
          openingValue: 'Primoværdi',
          interest: 'Rente',
          repayment: 'Afdrag',
          endingValue: 'Ultimoværdi',
          interestDays: 'Rentedage',
          interestOfMonth: 'Rente for perioden',
          booked: 'Bogført',
        },
      },
    },
    ContractsTransactions: {
      view: {
        title: 'Transaktioner',
        buttons: {
          bookByDate: 'Bogfør',
          importTransactions: 'Importér',
          bookByIds: 'Bogfør valgte',
        },
        importTransactionsModal: {
          title: 'Importér Transaktioner',
          template: 'transaktionsskabelon',
          actionButtons: {
            upload: 'Upload',
            cancel: 'Annuller',
            downloadTemplate: 'Download skabelon',
          },
        },
        filters: {
          options: {
            notBookedTransactions: 'Vis ikke bogførte',
          },
          dates: {
            fromDate: 'Fra dato',
            toDate: 'Til dato',
          },
        },
        datatable: {
          contractId: 'Kontraktnr.',
          dato: 'Dato',
          transactionSetId: 'Transaktions id',
          transactionType: 'Transaktionstype',
          accountNumber: 'Konto',
          amount: 'Beløb',
          contractValue: 'Kontraktværdi',
          booked: 'Bogført',
          vehicle: 'Køretøj',
          vatCode: 'Momskode',
          file: 'Bilag',
        },
      },
    },
    Settings: {
      title: 'Indstillinger for beregninger',
      tabs: {
        categoriesTabs: {
          buttons: {
            makeDatabaseCopy: 'Lav kopi af databasen',
            newSetting: 'Ny variabel',
          },
          tableColumns: {
            key: 'Nøgle',
            category: 'Kategori',
            description: 'Beskrivelse',
            value: 'Værdi',
            action: 'Rediger',
          },
          addEditSettingModal: {
            addTitle: 'Ny variabel',
            editTitle: 'Rediger variabel',
            fields: {
              key: 'Nøgle:',
              category: 'Category:',
              description: 'Beskrivelse:',
              value: {
                name: 'Værdi:',
                emailValidationMsg: 'Skal være en gyldig e-mailadresse.',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              addSubmit: 'Gem',
              editSubmit: 'Gem',
            },
          },
        },
        depreciationTab: {
          title: 'Afskrivningstabel',
          defaultSelectedOrganization: 'Standard Tabel',
          buttons: {
            generateDefaultTable: 'Generer standard tabel',
          },
          tableColumns: {
            id: 'Id',
            contract_length_month: 'Kontraktlængde i måneder',
            depreciation_percent: 'Afskrivninger i procent',
          },
          depreciation_percent_required: 'depreciation percent skal udfyldes.',
        },
        templatesTab: {
          title: 'Skabeloner',
          buttons: {
            selectTemplate: 'Vælg skabelon',
          },
          tableColumns: {
            contract_type: 'Kontrakttype',
            leasing_type: 'Leasingtype',
            template_type: 'Skabelonstype',
            provider_name: 'Skabelonsudbyder',
            provider_template_id: 'Skabelons ID',
            provider_template_name: 'Skabelonsnavn',
          },
          selectTemplateModal: {
            title: 'Vælg skabelon',
            fields: {
              contract_type: 'Kontrakttyper:',
              leasing_type: 'Leasingtyper:',
              template_type: 'Skabelonstyper:',
              provider_code: 'Skabelonsudbydere:',
              template_file: 'Skabelonsfiler:',
              aspose_word_template: 'Aspose Word Skabelon:',
              default_font_note: '*Skrifttypen for input-felterne er som standard Helvetica.',
              template_name: 'Skabeloner:',
            },
            actionButtons: {
              cancel: 'Annuller',
              submit: 'Gem',
            },
          },
        },
        tasksTab: {
          title: 'Opgaver',
          buttons: {
            addButton: 'Opret opgave',
            defaultAssignee: 'Vælg standard ansvarlig',
          },
          tableColumns: {
            id: 'ID',
            name: 'Opgavenavn',
            create_with_entity: 'Opgave oprettes ved kontraktoprettelse',
            actions: {
              name: 'Handlinger',
              buttonText: 'Rediger',
            },
          },
          defaultAssigneeModal: {
            title: 'Standard ansvarshavende',
            fields: {
              assignee_id: {
                name: 'Ansvarlig:',
                placeholder: 'Vælg venligst en ansvarlig',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              submit: 'Indstil standard ansvarshavende',
            },
          },
          addEditModal: {
            addTitle: 'Opret ny opgave',
            editTitle: 'Rediger opgave',
            fields: {
              name: {
                name: 'Opgavenavn:',
                placeholder: 'Opgavenavn',
              },
              create_with_entity: {
                name: 'Opret opgave ved kontraktoprettelse:',
                note: 'Opgaven bliver oprettet:',
              },
              due_title: 'Opgaven bliver oprettet:',
              due_days: {
                name: 'Dage:',
                placeholder: 'Vælg dage',
              },
              due_months: {
                name: 'Måneder:',
                placeholder: 'Vælg måneder',
              },
              due_start: {
                name: 'Før/efter:',
                placeholder: 'Vælg før/efter',
                options: {
                  before_contract_ends: 'Før kontrakten slutter',
                  today: 'Fra i dag',
                },
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              addSubmit: 'Tilføj',
              editSubmit: 'Gem',
            },
          },
        },
        customFieldsTab: {
          title: 'Andre kontrakt opl.',
          buttons: {
            addButton: 'Tilføj brugerdefineret felt',
          },
          tableColumns: {
            id: 'ID',
            name: 'Navn',
            key: 'Nøgle',
            default_value: 'Standard-værdi',
            actions: {
              name: 'Handlinger',
              buttonText: 'Rediger',
            },
          },
          addEditModal: {
            addTitle: 'Tilføj brugerdefineret felt',
            editTitle: 'rediger brugerdefineret felt',
            fields: {
              name: {
                name: 'Navn:',
                placeholder: 'Navn på felt',
              },
              default_value: {
                name: 'Standard værdi:',
                placeholder: 'Standard værdi for felt',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              addSubmit: 'Tilføj',
              editSubmit: 'Opdatering',
            },
          },
        },
        creditRatingTab: {
          title: 'Kreditvurdering',
          assetLiabilityTypes: {
            title: 'Balanceposter',
            buttons: {
              addButton: 'Opret en ny type',
            },
            tableColumns: {
              id: '',
              name: 'Navn',
              type: 'Type',
              actions: {
                name: 'Rediger',
                buttonText: 'Rediger',
              },
            },
            addEditModal: {
              addTitle: 'Opret en ny type',
              editTitle: 'Rediger type',
              fields: {
                name: {
                  name: 'Navn:',
                  placeholder: 'Aktiv/Passiv type navn',
                },
                type: {
                  name: 'Type:',
                  asset: 'Aktiv',
                  liability: 'Passiv',
                },
                show_on_online_application: 'Vis online formular:',
              },
              actionButtons: {
                cancel: 'Annuller',
                addSubmit: 'Tilføj',
                editSubmit: 'Opdatering',
              },
            },
          },
          incomeExpensesTypes: {
            title: 'Resultatposter',
            buttons: {
              addButton: 'Opret en ny type',
            },
            tableColumns: {
              id: '',
              name: 'Navn',
              type: 'Type',
              actions: {
                name: 'Rediger',
                buttonText: 'Rediger',
              },
            },
            addEditModal: {
              addTitle: 'Opret en ny type',
              editTitle: 'Rediger type',
              fields: {
                name: {
                  name: 'Navn:',
                  placeholder: 'Indkomst/Udgift type navn',
                },
                type: {
                  name: 'Type:',
                  income: 'Indkomst',
                  expense: 'Udgift',
                },
                show_on_online_application: 'Vis online formular:',
              },
              actionButtons: {
                cancel: 'Annuller',
                addSubmit: 'Tilføj',
                editSubmit: 'Opdater',
              },
            },
          },
        },
        enquireContractTab: {
          title: 'Formular indstillinger',
          module_name: 'Modul navn',
          tableColumns: {
            id: '',
            display_name: 'Navn',
            type: 'Type',
            is_required: 'Skal udfyldes',
            is_displayed: 'Bliver vist',
            is_system_field: 'Systemfelt',
            yes: 'Ja',
            no: 'Nej',
            actions: {
              name: 'Rediger',
              buttonText: 'Rediger',
            },
          },
          editModal: {
            title: 'Rediger modulfelter',
            fields: {
              is_displayed: 'Bliver vist:',
              is_required: 'Er obligatorisk:',
            },
            actionButtons: {
              cancel: 'Annuller',
              submit: 'Gem',
            },
          },
        },
        transactionsTemplatesTab: {
          title: 'Transaktionsskabeloner',
          buttons: {
            addButton: 'Tilføj',
          },
          tableColumns: {
            id: '',
            name: 'Navn',
            transactionTypes: 'Transaktionstyper',
            actions: {
              name: 'Handlinger',
              editbutton: 'Rediger',
              deletebutton: 'Delete',
            },
          },
          addEditModal: {
            addTitle: 'Ny Transaktionsskabelon',
            editTitle: 'Rediger Transaktionsskabelon',
            fields: {
              name: {
                name: 'Navn:',
                placeholder: 'Skabelonsnavn',
              },
              transaction_type: {
                name: 'Transaktionstype:',
                placeholder: 'Vælg transaktionstype',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              addSubmit: 'Tilføj',
              editSubmit: 'Gem',
            },
          },
        },
      },
    },
    Products: {
      view: {
        title: 'Produkter',
        buttons: {
          add: 'Opret nyt produkt',
        },
        datatable: {
          name: 'Navn',
          economicProductNumber: 'E-conomic varenr',
          action: 'Rediger',
        },
        addEditProductModal: {
          addTitle: 'Opret ny produkt',
          editTitle: 'Rediger produkt',
          fields: {
            name: 'Navn',
            economicProductNumber: 'E-conomic varenr',
            vatId: 'Momssats',
            fromToDateFlag: 'Vis til og fra dato',
            forSell: 'Kan viderefaktureres',
            forBuy: 'Kan udgiftsføres',
            priceExclVat: 'Pris ekskl. moms',
            costPriceExclVat: 'Kostpris ekskl. moms',
          },
          actionButtons: {
            cancel: 'Annuller',
            addSubmit: 'Tilføj',
            editSubmit: 'Opdatering',
          },
        },
      },
    },
    TransactionTypes: {
      view: {
        title: 'Transaktionstyper',
        buttons: {
          add: 'Opret ny transaktionstype',
        },
        datatable: {
          id: 'Id',
          serviceTypeName: 'Forretningsområde',
          transactionTypeName: 'Tekst',
          accountNumber: 'Bogføringskonto',
          action: 'Rediger',
        },
        addEditTransactionTypeModal: {
          addTitle: 'Ny transaktionstype',
          editTitle: 'Rediger transaktionstype',
          fields: {
            name: 'Dansk transaktionsnavn',
            serviceType: 'Forretningsområde',
            state: 'Aktiv',
            vat_required: 'Momspligtig',
            vat_code_id: 'Momskode',
            accountNumber: 'Bogføringskonto',
            contractValueTransaction: 'Transaktionen påvirker kontraktværdien',
            vehicleTransaction: 'Transaktion på køretøj',
          },
          actionButtons: {
            cancel: 'Annuller',
            addSubmit: 'Tilføj',
            editSubmit: 'Opdatering',
          },
        },
      },
    },
    Customers: {
      view: {
        title: 'Kunder',
        buttons: {
          add: 'Opret kunde',
          updateInvoices: 'Opdater fakturaer',
          filters: {
            title: 'Filtre',
            company: 'Firma',
            showAll: 'Vis alle',
          },
        },
        datatable: {
          id: 'Id',
          company_name: 'Firmanavn',
          name: 'Navn',
          forfalden: 'Forfalden',
          company: 'Firma',
          email: 'Email',
          phoneNumber: 'Telefonnummer',
          actions: 'Handlinger',
        },
      },
      add: {
        addTitle: 'Opret kunde',
        editTitle: 'Rediger kunde',
        company: 'Firma',
        private: 'Privat',
        fields: {
          company: {
            name: 'Firma',
            placeholder: '',
          },
          email: {
            name: 'Email',
            placeholder: '',
          },
          firstName: {
            name: 'Fornavn',
            placeholder: 'Fornavn',
          },
          lastName: {
            name: 'Efternavn',
            placeholder: 'Efternavn',
          },
          cpr: {
            name: 'CPR',
            placeholder: '',
          },
          address: {
            name: 'Adresse',
            placeholder: '',
          },
          paymentTerms: {
            name: 'Betalingsbetingelser',
            placeholder: 'Vælg betalingsbetingelser',
          },
          zipCode: {
            name: 'Postnummer',
            placeholder: '',
          },
          city: {
            name: 'By',
            placeholder: '',
          },
          phoneNumber: {
            name: 'Telefonnummer',
            placeholder: '',
          },
          bankRegistration: {
            name: 'Reg. nr.',
            placeholder: '',
          },
          bankAccountNumber: {
            name: 'Kontonr.',
            placeholder: '',
          },
          contactPersonFirstName: {
            name: 'Kontaktperson fornavn',
            placeholder: '',
          },
          contactPersonLastName: {
            name: 'Kontaktperson efternavn',
            placeholder: '',
          },
          cvrNumber: {
            name: 'CVR',
            placeholder: '',
          },
        },
        actionButtons: {
          submit: 'Gem',
          cancel: 'Annuller',
        },
      },
    },
    CustomerDetails: {
      editCustomerModal: {
        editButton: 'Rediger',
        editTitle: 'Rediger kunder',
        fields: {
          company: {
            name: 'Firma',
            placeholder: '',
          },
          email: {
            name: 'Email',
            placeholder: '',
          },
          firstName: {
            name: 'Fornavn',
            placeholder: 'Fornavn',
          },
          lastName: {
            name: 'Efternavn',
            placeholder: 'Efternavn',
          },
          cpr: {
            name: 'CPR',
            placeholder: '',
          },
          address: {
            name: 'Adresse',
            placeholder: '',
          },
          paymentTerms: {
            name: 'Betalingsbetingelser',
            placeholder: 'Vælg betalingsbetingelser',
          },
          zipCode: {
            name: 'Postnummer',
            placeholder: '',
          },
          city: {
            name: 'By',
            placeholder: '',
          },
          phoneNumber: {
            name: 'Telefonnummer',
            placeholder: '',
          },
          bankRegistration: {
            name: 'Reg. nr.',
            placeholder: '',
          },
          bankAccountNumber: {
            name: 'Kontonr.',
            placeholder: '',
          },
          contactPersonFirstName: {
            name: 'Kontaktperson fornavn',
            placeholder: '',
          },
          contactPersonLastName: {
            name: 'Kontaktperson efternavn',
            placeholder: '',
          },
          cvrNumber: {
            name: 'CVR',
            placeholder: '',
          },
          maritalStatus: {
            name: 'Civilstatus',
            placeholder: 'Vælg civilstatus',
          },
          occupationType: {
            name: 'Beskæftigelse',
            placeholder: 'Vælg beskæftigelse',
          },
          educationLevel: {
            name: 'Uddannelsesniveau',
            placeholder: 'Vælg uddannelsesniveau',
          },
          education: {
            name: 'Uddannelse',
            placeholder: 'Uddannelse',
          },
          housingType: {
            name: 'Boligtype',
            placeholder: 'Vælg boligtype',
          },
          memberOfUnion: {
            name: 'Fagforeningsmedlem',
            placeholder: '',
          },
          homeLivingChildren: {
            name: 'Antal hjemmeboende børn',
            placeholder: '',
          },
          creditScore: {
            name: 'Kredit score',
            placeholder: '',
          },
        },
        actionButtons: {
          submit: 'Gem',
          cancel: 'Annuller',
          editButton: 'Rediger',
          editTitle: 'Rediger kunder',
          fields: {
            company: {
              name: 'Firma',
              placeholder: '',
            },
            email: {
              name: 'Email',
              placeholder: '',
            },
            firstName: {
              name: 'Fornavn',
              placeholder: 'Fornavn',
            },
            lastName: {
              name: 'Efternavn',
              placeholder: 'Efternavn',
            },
            cpr: {
              name: 'CPR',
              placeholder: '',
            },
            address: {
              name: 'Adresse',
              placeholder: '',
            },
            paymentTerms: {
              name: 'Betalingsbetingelser',
              placeholder: 'Vælg betalingsbetingelser',
            },
            zipCode: {
              name: 'Postnummer',
              placeholder: '',
            },
            city: {
              name: 'By',
              placeholder: '',
            },
            phoneNumber: {
              name: 'Telefonnummer',
              placeholder: '',
            },
            bankRegistration: {
              name: 'Reg. nr.',
              placeholder: '',
            },
            bankAccountNumber: {
              name: 'Kontonr.',
              placeholder: '',
            },
            contactPersonFirstName: {
              name: 'Kontaktperson fornavn',
              placeholder: '',
            },
            contactPersonLastName: {
              name: 'Kontaktperson efternavn',
              placeholder: '',
            },
            cvrNumber: {
              name: 'CVR',
              placeholder: '',
            },
            maritalStatus: {
              name: 'Civilstatus',
              placeholder: 'Vælg civilstatus',
            },
            occupationType: {
              name: 'Beskæftigelse',
              placeholder: 'Vælg beskæftigelse',
            },
            educationLevel: {
              name: 'Uddannelsesniveau',
              placeholder: 'Vælg uddannelsesniveau',
            },
            education: {
              name: 'Uddannelse',
              placeholder: 'Uddannelse',
            },
            housingType: {
              name: 'Boligtype',
              placeholder: 'Vælg boligtype',
            },
            memberOfUnion: {
              name: 'Fagforeningsmedlem',
              placeholder: '',
            },
            homeLivingChildren: {
              name: 'Antal hjemmeboende børn',
              placeholder: '',
            },
            creditScore: {
              name: 'Kredit score',
              placeholder: '',
            },
          },
          actionButtons: {
            submit: 'Gem',
            cancel: 'Annuller',
          },
        },
        tabs: {
          customerInformation: {
            title: 'Kundeoplysninger',
            nameAndAddress: {
              title: 'Navn og adresse',
              info: {
                id: 'Id:',
                firstName: 'Fornavn:',
                company: 'Firma:',
                type: 'Type:',
                cprOrCvr: 'CPR/CVR:',
                address: 'Adresse:',
                city: 'By:',
                economicId: 'Economic Id:',
                lastName: 'Efternavn:',
                email: 'Email:',
                zipCode: 'Postnummer:',
                phoneNumber: 'Telefonnummer:',
              },
            },
            others: {
              title: 'Øvrige',
              info: {
                bankRegistration: 'Reg. nr.:',
                bankAccountNumber: 'Kontonr:',
                paymentTerm: 'Betalingsbetingelser:',
                maritalStatus: 'Civilstatus:',
                occupationType: 'Beskæftigelse:',
                education: 'Uddannelse:',
                educationLevel: 'Uddannelsesniveau:',
                housingType: 'Boligtype:',
                memberOfUnion: 'Fagforeningsmedlem:',
                homeLivingChildren: 'Antal hjemmeboende børn:',
                creditScore: 'Kredit score:',
                driverLicenseFile: 'Evt. kørekort:',
                socialSecurityFile: 'Identifikation på kunde:',
                download: 'Download',
                loading: 'Henter...',
              },
            },
          },
          leasingOffers: {
            title: 'Leasingtilbud',
            tableColumns: {
              id: 'Id',
              brand: 'Mærke',
              model: 'Model',
              payout: 'Udbetaling',
              securityDeposit: 'Depositum',
              monthlyLeasePayments: 'Månedlig leasingydelse',
              contractLengthInMonths: 'Kontraktlængde i måneder',
              residualValue: 'Restværdi',
              activeMonthsAYear: 'Aktive måneder om året',
              actions: 'Handlinger',
              inquery: 'Forespørg',
              edit: 'Rediger',
              send: 'Send',
              print: 'Print',
            },
          },
          leases: {
            activeLeases: 'Aktive leasingkontrakter',
            historicalLeases: 'Historiske leasingkontrakter',
            tableColumns: {
              leasing_contract: 'Kontraktnr.',
              owner_name: 'Ansvarlig',
              vehicle: 'Køretøj',
              monthly_payment_cents: 'Månedlig ydelse',
              contract_length_in_months: 'Løbetid i måneder',
              termination_date: 'Slut dato',
              state: 'Status',
              actions: 'Handlinger',
            },
          },
          notes: {
            title: 'Noter',
            tableColumns: {
              creator_name: 'Bruger',
              leasing_contract: 'Kontraktnr.',
              message_date: 'Dato',
              text: 'Tekst',
            },
          },
          invoices: {
            title: 'Fakturaer',
            invoiceStatusSelect: {
              showAll: 'Vis alle',
              paid: 'Betalt',
              overdue: 'Overskredet',
              notPaid: 'Ikke betalt',
            },
            tableColumns: {
              economicInvoiceBooked: 'Fakturanr.',
              leasingContract: 'Kontraktnr.',
              netAmountCents: 'Beløb',
              remainderAmountCents: 'Forfalden',
              date: 'Dato',
              dueDate: 'Forfaldsdato.',
              overdueDays: 'Forfalden i dage',
            },
          },
          financialInfo: {
            title: 'Indkomst og formue',
            customerFinancialInfoModal: {
              title: 'Indkomst og formue',
              buttonText: 'Tilføj indtægt, udgift el. andet',
              fields: {
                date: {
                  name: 'Dato',
                  placeholder: '',
                },
                type: {
                  name: 'Type',
                  options: {
                    income: 'Indkomst',
                    expense: 'Udgifter',
                    asset: 'Værdier',
                    liability: 'Gæld',
                  },
                },
                value: {
                  name: 'Værdi',
                  placeholder: 'Vælg type',
                },
                amount: {
                  name: 'Beløb',
                  note: 'udgifter og gæld skal påføres et -',
                },
              },
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            uploadTaxModal: {
              title: 'Upload årsopgørelse eller lønseddel',
              buttonText: 'Upload årsopgørelse eller lønseddel',
              fields: {
                date: {
                  name: 'Dato',
                  placeholder: '',
                },
                fileType: {
                  name: 'Filtype',
                  options: {
                    tax_statement: 'Årsopgørelse',
                    paycheck: 'Lønseddel',
                  },
                  placeholder: '',
                },
                file: {
                  name: 'Fil',
                  placeholder: '',
                },
              },
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            assetLiabilityTable: {
              availableDates: 'Tilgængelige datoer',
              previousDates: 'Tidligere indtastninger',
              table: {
                name: 'Navn',
                delete: 'Slet',
                assets: 'Værdier',
                liabilities: 'Gæld',
                assetsAndLiabilities: 'Værdier + gæld',
                incomes: 'Indkomst',
                expenses: 'Udgifter',
                incomeAndExpenses: 'Indkomst + udgifter',
              },
            },
            incomeDocumentsTable: {
              title: 'Dokumentation for indkomst',
              tableColumns: {
                id: 'Id',
                date: 'Dato',
                type: 'Type',
                actions: 'Handlinger',
              },
            },
          },
        },
      },
      tabs: {
        customerInformation: {
          title: 'Kundeoplysninger',
          nameAndAddress: {
            title: 'Navn og adresse',
            info: {
              id: 'Id:',
              firstName: 'Fornavn:',
              company: 'Firma:',
              type: 'Type:',
              cprOrCvr: 'CPR/CVR:',
              address: 'Adresse:',
              city: 'By:',
              economicId: 'Economic Id:',
              lastName: 'Efternavn:',
              email: 'Email:',
              zipCode: 'Postnummer:',
              phoneNumber: 'Telefonnummer:',
            },
          },
          others: {
            title: 'Øvrige',
            info: {
              bankRegistration: 'Reg. nr.:',
              bankAccountNumber: 'Kontonr:',
              paymentTerm: 'Betalingsbetingelser:',
              maritalStatus: 'Civilstatus:',
              occupationType: 'Beskæftigelse:',
              education: 'Uddannelse:',
              educationLevel: 'Uddannelsesniveau:',
              housingType: 'Boligtype:',
              memberOfUnion: 'Fagforeningsmedlem:',
              homeLivingChildren: 'Antal hjemmeboende børn:',
              creditScore: 'Kredit score:',
              driverLicenseFile: 'Evt. kørekort:',
              socialSecurityFile: 'Identifikation på kunde:',
              download: 'Download',
              loading: 'Henter...',
            },
          },
        },
        leasingOffers: {
          title: 'Leasingtilbud',
          tableColumns: {
            id: 'Id',
            brand: 'Mærke',
            model: 'Model',
            payout: 'Udbetaling',
            securityDeposit: 'Depositum',
            monthlyLeasePayments: 'Månedlig leasingydelse',
            contractLengthInMonths: 'Kontraktlængde i måneder',
            residualValue: 'Restværdi',
            activeMonthsAYear: 'Aktive måneder om året',
            actions: 'Handlinger',
          },
        },
        leases: {
          activeLeases: 'Aktive leasingkontrakter',
          historicalLeases: 'Historiske leasingkontrakter',
          tableColumns: {
            leasing_contract: 'Kontraktnr.',
            owner_name: 'Ansvarlig',
            vehicle: 'Køretøj',
            monthly_payment_cents: 'Månedlig ydelse',
            contract_length_in_months: 'Løbetid i måneder',
            termination_date: 'Slut dato',
            state: 'Status',
            actions: 'Handlinger',
          },
        },
        notes: {
          title: 'Noter',
          tableColumns: {
            creator_name: 'Bruger',
            leasing_contract: 'Kontraktnr.',
            message_date: 'Dato',
            text: 'Tekst',
          },
        },
        invoices: {
          title: 'Fakturaer',
          invoiceStatusSelect: {
            showAll: 'Vis alle',
            paid: 'Betalt',
            overdue: 'Overskredet',
            notPaid: 'Ikke betalt',
          },
          tableColumns: {
            economicInvoiceBooked: 'Fakturanr.',
            leasingContract: 'Kontraktnr.',
            netAmountCents: 'Beløb',
            remainderAmountCents: 'Forfalden',
            date: 'Dato',
            dueDate: 'Forfaldsdato.',
            overdueDays: 'Forfalden i dage',
          },
        },
        financialInfo: {
          title: 'Indkomst og formue',
          customerFinancialInfoModal: {
            title: 'Indkomst og formue',
            buttonText: 'Tilføj indtægt, udgift el. andet',
            fields: {
              date: {
                name: 'Dato',
                placeholder: '',
              },
              type: {
                name: 'Type',
                options: {
                  income: 'Indkomst',
                  expense: 'Udgifter',
                  asset: 'Værdier',
                  liability: 'Gæld',
                },
              },
              value: {
                name: 'Værdi',
                placeholder: 'Vælg type',
              },
              amount: {
                name: 'Beløb',
                note: 'udgifter og gæld skal påføres et -',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              submit: 'Gem',
            },
          },
          uploadTaxModal: {
            title: 'Upload årsopgørelse eller lønseddel',
            buttonText: 'Upload årsopgørelse eller lønseddel',
            fields: {
              date: {
                name: 'Dato',
                placeholder: '',
              },
              fileType: {
                name: 'Filtype',
                options: {
                  tax_statement: 'Årsopgørelse',
                  paycheck: 'Lønseddel',
                },
                placeholder: '',
              },
              file: {
                name: 'Fil',
                placeholder: '',
              },
            },
            actionButtons: {
              cancel: 'Annuller',
              submit: 'Gem',
            },
          },
          assetLiabilityTable: {
            availableDates: 'Tilgængelige datoer',
            previousDates: 'Tidligere indtastninger',
            table: {
              name: 'Navn',
              delete: 'Slet',
              assets: 'Værdier',
              liabilities: 'Gæld',
              assetsAndLiabilities: 'Værdier + gæld',
              incomes: 'Indkomst',
              expenses: 'Udgifter',
              incomeAndExpenses: 'Indkomst + udgifter',
            },
          },
          incomeDocumentsTable: {
            title: 'Dokumentation for indkomst',
            tableColumns: {
              id: 'Id',
              date: 'Dato',
              type: 'Type',
              actions: 'Handlinger',
            },
          },
        },
      },
      Offers: {
        view: {
          title: 'Tilbud',
          buttons: {
            newDeal: 'Nyt tilbud',
            addOffersToCustomer: 'Tilføj valgte tilbud til ny kunde',
            filters: 'Filtre',
            clearFilters: 'Nulstil filtre',
            company: 'Firma',
            showAll: 'Vis alle',
            type: 'Type',
          },
          datatable: {
            offerId: 'Tilbudsnr.',
            organization_name: 'Firmanavn',
            customer_name: 'Navn',
            company_name: 'Firma',
            leasing_type: 'Leasingtype',
            contract_type: 'Kontrakttype',
            customer_email: 'Email',
            vehicle_brand_name: 'Mærke',
            vehicle_model: 'Model',
            down_payment_cents: 'Udbetaling',
            salvage_value_cents: 'Restværdi',
            add_to_frontpage: 'Vis på forside',
            actions: {
              name: 'Handlinger',
              options: {
                requestContract: 'Forespørg',
                edit: 'Rediger',
                sendToCustomer: 'Send til kunden',
                print: 'Print',
              },
            },
          },
          addOffersToCustomerModal: {
            title: 'Tilføj valgte tilbud til ny kunde',
            fields: {
              chooseCustomer: 'Vælg kunde',
              customer: 'Kunde',
              createCustomer: 'Opret kunde',
              private: 'Privat',
              company: 'Firma',
              firstName: 'Fornavn',
              lastName: 'Efternavn',
              email: 'Email',
              contactPersonFirstname: 'Kontaktperson fornavn',
              contactPersonLastname: 'Kontaktperson efternavn',
            },
            actionButtons: {
              submit: 'Gem',
              cancel: 'Annuller',
            },
          },
          enquireForContractModal: {
            title: 'Forespørg om kontrakt',
            contractInfo: {
              title: 'Contract Info',
              fields: {
                firstName: 'Fornavn',
                lastName: 'Efternavn',
                email: 'Email',
                company: 'Firma',
                address: 'Adresse',
                zip_code: 'Postnummer',
                city: 'By',
                telephone: 'Telefonnummer',
                cpr_number: 'CPR',
                cvr_number: 'CVR',
                bank_registration: 'Reg. nr.',
                bank_account_number: 'Kontonr.',
                payment_term: {
                  name: 'Betalingsbetingelser',
                  placeholder: 'Vælg betalingsbetingelser',
                },
                engine: 'Motor',
                car_type: 'Biltype',
                version: 'Udstyrsvariant',
                vehicle_market_value: 'Markedsværdi',
                vehicle_market_value_year: 'Markedsværdi om et år',
                vehicle_mileage: 'Kørte km',
                ekstra: 'Ekstraudstyr',
                fuel: 'Brændstof',
                transmission: 'Transmission',
              },
            },
            attachFiles: {
              title: 'Vædhæft filer',
              fields: {
                social_security_file: 'Identifikation på kunde',
                driver_license_file: 'Evt. kørekort',
                date: 'Date',
                documentType: 'Dokument type',
                documentFile: 'Dokument fil',
              },
            },
            actionButtons: {
              submit: 'Gem',
              cancel: 'Annuller',
            },
          },
        },
        add: {
          steps: {
            titles: {
              deals: 'Tilbud',
              newOffer: 'Nyt tilbud',
              editOffer: 'Rediger tilbud',
              editOfferNo: 'Rediger Tilbudnr.',
            },
            marketPriceCalculatorStep: {
              title: 'Handelsprisberegner',
            },
            leasingCalculatorStep: {
              title: 'Leasingberegner',
            },
            vehicleAndCustomerStep: {
              title: 'Køretøj & kunde',
              dealsVehicle: {
                vehicle_make: 'Mærke',
                vehicle_model: 'Model',
              },
              dealsCustomer: {
                chooseCustomer: 'Vælg kunde',
                createCustomer: 'Opret kunde',
                customer: 'Kunde',
                private: 'Privat',
                company: 'Firma',
                first_name: 'Fornavn',
                last_name: 'Efternavn',
                email: 'Email',
                telephone: 'Telephone',
                contact_person_first_name: 'Kontaktperson fornavn',
                contact_person_last_name: 'Kontaktperson efternavn',
              },
            },
            offerCreatedStep: {
              title: 'Tilbud oprettet',
              print: 'Print',
              sendToCustomer: 'Send til kunden',
              goToCustomer: 'Gå til kunden',
              goToDeals: 'Gå til leasingtilbud',
            },
            stepsActions: {
              back: 'Tilbage',
              next: 'næste',
              skip: 'Spring over',
              createOffer: 'Opret tilbud',
              print: 'Print',
              save: 'Gem',
              send: 'Send',
              inquire: 'Forespørg',
            },
          },
        },
      },
      ContractTasks: {
        view: {
          title: 'Opgaver',
          filters: {
            status: {
              all: 'Alle',
              completed: 'Udført',
              notCompleted: 'Ikke udført',
            },
          },
          datatable: {
            id: 'ID',
            contract_id: 'Kontrakt',
            assignee: 'Ansvarlig',
            task: 'Opgavenavn',
            due_date: 'Frist',
            status: {
              name: 'Status',
              completed: 'Udført',
              notCompleted: 'Ikke udført',
            },
            actions: {
              name: 'Handlinger',
              markAsDone: 'Marker som udført',
              markAsNotDone: 'Marker som Ikke udført',
              edit: 'Rediger',
              openContract: 'Åben kontrakt',
            },
          },
        },
      },
      Contracts: {
        view: {
          title: 'Kontrakt',
          buttons: {
            newContract: 'Ny kontrakt',
            filters: 'Filtre',
            clearFilters: 'Nulstil filtre',
            company: 'Firma',
            showAll: 'Vis alle',
            type: 'Type',
            status: 'Status',
            vat: 'Moms',
            EU_vat: 'EU moms',
          },
          datatable: {
            leasing_contract_id: 'Kontraktnr.',
            organization_name: 'Firmanavn',
            owner_name: 'Ansvarlig',
            leasing_type: 'Leasingtype',
            contract_type: 'Kontrakttype',
            customer_name: 'Navn',
            company_name: 'Firma',
            vehicle: 'Køretøj',
            vehicle_serial_number: 'Stelnr.',
            vehicle_plate_number: 'køretøjets pladenummer',
            termination_date: 'Slut dato',
            contract_value: 'Kontraktværdi',
            state: 'Status',
            contract_completion_status: 'Opgaver',
            actions: 'Rediger',
          },
        },
        newContractPage: {
          steps: {
            titles: {
              contract: 'Kontrakt',
              newContract: 'Ny kontrakt',
              editContract: 'Rediger Kontrakt',
            },
            marketPriceCalculatorStep: {
              title: 'Handelsprisberegner',
            },
            leasingCalculatorStep: {
              title: 'Leasingberegner',
            },
            vehicleInformationStep: {
              title: 'Køretøjsoplysninger',
              viewVehicleInfo: {
                others: 'Øvrige',
                id: 'Id:',
                type: 'Type:',
                brand: 'Mærke:',
                model: 'Model:',
                usedVat: 'Brugtmoms:',
                yes: 'Ja',
                no: 'Ingen',
                date_of_first_registration: 'Første registreringsdato:',
                serial_number: 'Stelnr.',
                vehicle_plate_number: 'køretøjets pladenummer',
                fuel: 'Brændstof:',
                engine: 'Motor:',
                carType: 'Biltype:',
                transmission: 'Transmission:',
                version: 'Udstyrsvariant:',
                ekstra: 'Ekstraudstyr:',
                marketValue: 'Markedsværdi:',
                mileage: 'Kørte km:',
                changeVehicleModal: {
                  title: 'Skift køretøj',
                  buttonText: 'Skift køretøj',
                  vehicle: 'Køretøj',
                  please_choose_the_new_vehicle: 'Vælg det ønskede køretøj',
                  actionButtons: {
                    cancel: 'Annuller',
                    submit: 'Gem',
                  },
                },
                updateVehiclePlateNumberModal: {
                  title: 'Opdater køretøjs registreringsnummer',
                  vehicle_plate_number: 'køretøjets pladenummer',
                  vehicle: 'Køretøj',
                  actionButtons: {
                    cancel: 'Annuller',
                    submit: 'Gem',
                  },
                },
              },
              addVehicle: {
                selectVehicle: 'Vælg Køretøj',
                createVehicle: 'Opret Køretøj',
                vehicle: 'køretøj',
                brand: 'Mærke',
                model: 'Model',
                usedVat: 'Brugtmoms',
                date_of_first_registration: 'Første registreringsdato',
                serial_number: 'Stelnr.',
                fuel: 'Brændstof',
                engine: 'Motor',
                carType: 'Biltype',
                transmission: 'Transmission',
                version: 'Udstyrsvariant',
                ekstra: 'Ekstraudstyr',
                marketValue: 'Markedsværdi',
                mileage: 'Kørte km.',
                condition: 'Stand',
                euro_ncap: '5-stjernet NCAP markering',
                abs: 'ABS',
                customs_inpsections: 'Toldsynet',
                price_as_new_cents: 'Nypris',
                km_per_litre: 'km/l',
                num_airbags: 'Antal airbags',
                num_seat_belts: 'Antal selealarmer',
                num_integrated_children_seats: 'Antal integrerede børnesæder',
              },
            },
            customerInformationStep: {
              title: 'Kundeoplysninger',
              chooseCustomer: 'Vælg kunde',
              createCustomer: 'Opret kunde',
              customer: 'Kunde',
              private: 'Privat',
              company: 'Firma',
              first_name: 'Fornavn',
              last_name: 'Efternavn',
              email: 'Email',
              contactPersonFirstname: 'Kontaktperson fornavn',
              contactPersonLastname: 'Kontaktperson efternavn',
              cpr_number: 'CPR',
              cvr_number: 'CVR',
              address: 'Adresse',
              zip_code: 'Postnummer',
              city: 'By',
              telephone: 'Telefonnummer',
              bank_registration: 'Reg. nr.',
              bank_account_number: 'Kontonr.',
              payment_term: {
                name: 'Betalingsbetingelser',
                placeholder: 'Vælg betalingsbetingelser',
              },
              viewCustomerInfo: {
                id: 'Id:',
                type: 'Type:',
                first_name: 'Fornavn:',
                last_name: 'Efternavn:',
                email: 'Email:',
                cpr_cvr_number: 'CPR/CVR:',
                address: 'Adresse:',
                zip_code: 'Postnummer:',
                city: 'By:',
                company: 'Firma:',
                telephone: 'Telefonnummer:',
              },
            },
            contractCreatedStep: {
              title: 'Kontrakt oprettet',
              print: 'Print',
              sendToCustomer: 'Send til kunden',
              goToCustomer: 'Gå til kunden',
              goToPrivateCustomer: 'Gå til privatkunden',
              goToCompanyCustomer: 'Gå til erhvervskunden',
              goToContracts: 'Gå til kontrakter',
            },
            stepsActions: {
              back: 'Tilbage',
              next: 'næste',
              skip: 'Spring over',
              create: 'Opret',
              edit: 'Rediger',
              print: 'Print',
              save: 'Gem',
              send: 'Send',
              inquire: 'Forespørg',
            },
          },
        },
        editContractPage: {
          tabs: {
            contractTab: {
              title: 'Kontrakt',
              actionsMenu: {
                title: 'Handlinger',
                cancelContract: 'Annuller kontrakt',
                restoreContract: 'Genåben kontrakt',
                copyContract: 'Lav tilbud',
              },
              tasksTitle: 'Opgavelisten',
              addSecondaryCustomerModal: {
                title: 'Vælg eller Opret kunde',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
              selectCustomerToDeactivateModal: {
                title: 'Vælg kunde',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
              moveOrSellVehicleModal: {
                title: 'Sælg eller flyt køretøj?',
                sell_vehicle: 'Sælg køretøj',
                move_vehicle: 'Flyt køretøj',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Næste',
                },
              },
              requestEUvatRefundModal: {
                title: 'Annmod om EU-moms refundering',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Marker som udført',
                },
              },
              contractCreationModal: {
                title: 'Udskriv kontrakt',
                downloadDreaft: 'Dan kontrakt',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Marker som udført',
                },
              },
              contractUploadModal: {
                title: 'Upload kontrakt',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Upload underskrevet kontrakt',
                },
              },
              insuranceTaskModal: {
                title: 'Opret forsikring',
                createInsurance: 'Opret forsikring',
                customerhasInsuredTheVehicle: 'Kunde har selv forsikret køretøjet',
                copyToClipboard: 'Kopier til udklipsholder',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Marker som udført',
                },
              },
              createUpfrontPaymentsModal: {
                title: 'Opret førstegangsbetalinger.',
                fields: {
                  date: 'Dato',
                  payment_term_number: {
                    name: 'Betalingsbetingelser',
                    placeholder: 'Vælg betalingsbetingelser',
                  },
                },
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
              creditApproveModal: {
                title: 'Kreditgodkendelse',
                fields: {
                  customer: 'Kunde',
                  status: {
                    name: 'Status',
                    placeholder: 'Vælg status',
                    approved: 'Godkendt',
                    denied: 'Afvist',
                  },
                  message_text: 'Tekst',
                },
                assetsLiabilitySummary: {
                  title: 'Oversigt over aktiver/passiver',
                  addNewCreditNote: 'Hvis du ønsker at tilføje nye kreditinformationer eller se specifikationer.',
                  goToFinancialInfo: 'Gå til finannciel info',
                  dates: 'Datoer',
                  assets_liabilites: 'Aktiver - Passiver',
                  income_expenses: 'Indkomst - Udgifter',
                  customerIncomeDocuments: 'Dokumentation for indkomst',
                },
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
              generatePaymentTaskModal: {
                title: 'Levering og oprettelse af md. betalinger',
                fields: {
                  start_date: 'Start dato',
                  delivery_date: 'Leveringsdato',
                  process_date: 'Effektueringsdato',
                  final_registration_tax: 'Endelig registreringsafgift',
                  full_payments: 'Opret månedlige betalinger som fulde betalinger fra startdatoen.',
                },
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Tilføj',
                },
              },
            },
            paymentsTab: {
              title: 'Betalinger',
              addPaymentModal: {
                title: 'Tilføj betaling',
                buttonText: 'Tilføj betaling',
                fields: {
                  payment_type: 'Betalingstype',
                  date: 'Dato',
                  customer: 'kunde',
                  tax_vat_cents: 'Beløb i kr. ekskl. moms',
                  vat_cents: 'Moms',
                  from_date: 'Fra dato',
                  to_date: 'Til dato',
                },
                actionButtons: {
                  submit: 'Tilføj',
                  cancel: 'Annuller',
                },
              },
              generatePayment: {
                title: 'Posteringer (entries)',
                buttonText: 'Dan månedlige betalinger',
                fields: {
                  payment_type: 'Betalingstype',
                  start_date: 'Start dato',
                  delivery_date: 'Leveringsdato',
                  monthly_payment: 'Månedlig beløb (inkl. moms)',
                  termination_date: 'Slut dato',
                  process_date: 'Effektueringsdato',
                  to_date: 'Til dato',
                  full_payments: 'Opret månedlige betalinger som fulde betalinger fra startdatoen.',
                },
                actionButtons: {
                  submit: 'Tilføj',
                  cancel: 'Annuller',
                },
              },
              addInvoiceModal: {
                title: 'Opret og bogfør fakturaerne for betalingerne',
                buttonText: 'Opret faktura',
                invoicesNote:
                  'BEMÆRK: Alle fakturaerne vil blive oprettet på den valgte bogføringsdato og med opkrævningsdatoen, der følger betalingsbetingelserne.',
                fields: {
                  payment_book_date: 'Betalinger til og med',
                  due_date: 'Bogføringsdato',
                  payment_term_number: {
                    name: 'Betalingsbetingelser',
                    placeholder: 'Vælg betalingsbetingelser',
                  },
                },
                actionButtons: {
                  submit: 'Opret og bogfør',
                  cancel: 'Annuller',
                },
              },
              datatable: {
                Id: 'Id',
                customer_name: 'Navn',
                product_name: 'Produkt',
                payment_date: 'Dato',
                text: 'Tekst',
                booked: 'Bogført',
                payment_excl_tax_vat_cents: 'Beløb i kr. ekskl. moms',
                vat_cents: 'Moms',
                vat_deduction_cents: 'Momsfradrag',
                payment_incl_tax_vat_cents: 'Beløb i alt',
                actions: 'Handlinger',
              },
            },
            notesTab: {
              title: 'Noter',
              addNoteModal: {
                title: 'Tilføj note',
                buttonText: 'Tilføj note',
                fields: {
                  message_date: 'Dato',
                  customer: 'Kunde',
                  message_text: 'Tekst',
                },
                actionButtons: {
                  submit: 'Gem',
                  cancel: 'Annuller',
                },
              },
              datatable: {
                customer_name: 'Navn',
                creator_name: 'Bruger',
                message_date: 'Dato',
                text: 'Tekst',
              },
            },
            transactionsTab: {
              title: 'Transaktioner',
              buttons: {
                importCashEntries: 'Bogfør i e-conomic',
                runInterestMonthly: 'Tilskriv rente manuelt',
                closeContract: 'Afslut kontrakt',
              },
              addTransactionsModal: {
                title: 'Tilføj transaktioner',
                buttonText: 'Tilføj transaktioner',
                fields: {
                  selectTemplate: {
                    name: 'Vælg skabelon',
                    placeholder: 'Vælg transaktionsskabelon',
                  },
                  saveTemplate: 'Gem skabelonn',
                  templateName: 'Skabelonsnavn',
                  transaction_date: 'Dato',
                  transaction_type: 'Transaktionstype',
                  amount_cents: 'Beløb',
                  vat_code: 'Momskode',
                  vehicle: 'Køretøj',
                  sum_of_transactions_note: 'Summen for transaktionen er',
                  transaction_record_files_placeholder: 'Klik eller træk filer hertil for at uploade',
                  sellVehicleModal: {
                    payment_product: 'Betalingstype',
                    date: 'Dato',
                    customer: {
                      name: 'Kunde',
                      placeholder: 'Vælg kunde',
                      create_new_customer_note: 'Opret ny kunde',
                    },
                    payment_term: 'Betalingsbetingelser',
                    payment_excl_tax_vat: 'Beløb i kr. ekskl. moms',
                    payment_vat: 'Moms',
                    transaction_amount: 'Kontraktværdien nedskrives med',
                  },
                },
                actionButtons: {
                  submit: 'Gem',
                  cancel: 'Annuller',
                },
              },
              datatable: {
                transaction_date: 'Dato',
                transaction_set: 'Transaktions id',
                transaction_type: 'Transaktionstype',
                account_number: 'Konto',
                amount_cents: 'Beløb',
                contract_value: 'Kontraktværdi',
                booked: 'Bogført',
                vehicle: 'Køretøj',
                vat_code: 'Momskode',
                download_document: 'Bilag',
              },
            },
            estimatedPaymentsTab: {
              title: 'Folsomhedsberegninger',
              datatable: {
                estimated_monthly_payment_id: 'Id',
                estimated_registration_tax_lower_cents: 'Registreringsafgiften mindre end',
                estimated_monthly_payment_cents: 'Leasingydelse inkl. moms',
              },
            },
            amortizationsTab: {
              title: 'Amortisering',
              buttons: {
                postAmortizations: 'Dan amortisering',
                postRepaymentTransactions: 'Dan afdragstransaktioner',
              },
              datatable: {
                contract_payment_id: 'Betalingsid',
                from_date: 'Fra dato',
                to_date: 'Til dato',
                payment_excl_tax_vat: 'Betaling',
                opening_value: 'Primoværdi',
                interest: 'Rente',
                repayment: 'Afdrag',
                ending_value: 'Ultimoværdi',
                interest_days: 'Rentedage',
                interest_of_month: 'Rente for perioden',
                booked: 'Bogført',
              },
            },
            customerInformationTab: {
              title: 'Kundeoplysninger',
              customerTab: {
                title: 'Kundedetaljer',
                changeCustomerModal: {
                  title: 'Ændre kunde(r)',
                  buttonText: 'Ændre kunde(r)',
                  tabs: {
                    primary: {
                      title: 'Primær',
                      subtitle: 'Nuværende kunde',
                      fields: {
                        end_date: 'Slut dato',
                        newCustomer: {
                          title: 'Ny kunde',
                        },
                      },
                      submitButton: 'Gem',
                    },
                    secondary: {
                      title: 'Sekundær',
                      subtitle: 'Sekundær bruger',
                      fields: {
                        end_date: 'Slut dato',
                        newSecondaryUser: {
                          title: 'Ny sekundær bruger',
                          chooseCustomer: 'Vælg kunde',
                          start_date: 'Start dato',
                        },
                      },
                      submitButton: 'Gem',
                    },
                    guarantor: {
                      title: 'Kautionist',
                      subtitle: 'Kautionist',
                      fields: {
                        new_guarantor: 'Tilføj kautionist',
                        start_date: 'Start dato',
                        currentGuarantorCustomer: {
                          title: 'Kautionist',
                          end_date: 'Slut dato',
                        },
                      },
                      submitButton: 'Gem',
                    },
                    historic: {
                      title: 'Historisk',
                      subtitle: 'Historik',
                      table: {
                        name: 'Navn',
                        type: 'Type',
                        from_date: 'Fra dato',
                        to_date: 'Til dato',
                      },
                    },
                  },
                  actionButtons: {
                    cancel: 'Annuller',
                  },
                },
              },
              incomeAndExpensesTab: {
                title: 'indkomst og formue',
              },
              invoicesTab: {
                title: 'Fakturaer',
              },
            },
            vehicleTab: {
              title: 'Køretøjsoplysninger',
              selectedInvoiceStatus: {
                showAll: 'Vis alle',
                paid: 'Betalt',
                overdue: 'Overskredet',
                notPaid: 'Ikke betalt',
              },
            },
            documentsTab: {
              title: 'Dokumenter',
              buttons: {
                generateDraftContract: 'Dan kontrakt',
                deleteDraftContract: 'Slet dannet kontrakt',
                downloadSignedContract: 'Download underskrevet kontrakt',
                deleteSignedContract: 'Slet underskrevet kontrakt',
                uploadSignedContract: 'Upload underskrevet kontrakt',
              },
            },
            mileageSplitDetailsTab: {
              title: 'Kørselsopgørelse',
              date_of_statement: 'Opgørelsesdato',
              populateTable: 'Hent fordeling',
              createSplitPayments: 'Dan udligningsbetalinger.',
              table: {
                columns: {
                  private: 'Privat',
                  company: 'Erhverv',
                  total: 'Total',
                },
                rows: {
                  mileage: 'Kilometer',
                  split: 'Kørselsfordeling',
                  charged_per_customer: 'Opkrævet beløb',
                  actual_payments: 'Beløb til opkrævning, fordelt efter faktiske km.',
                  due_owed_by_customer: 'Beløb til udligning.',
                },
              },
            },
            mileageTableTab: {
              title: 'Kørselsoversigt',
              datatable: {
                customer_id: 'Kundenr.',
                from_date: 'Fra dato',
                to_date: 'Til dato',
                trip_type: 'Kørselstype',
                kilometer: 'Kilometer',
                actions: 'Handlinger',
              },
              buttons: {
                importContractMileage: 'Upload kørselsdata',
                downloadContractMileage: 'Download kørselsdata',
                updateMileage: 'Opdater kørselsdata',
              },
            },
            internalRateTab: {
              title: 'Nøgletal',
              internalRateInPercent: 'Intern rente i pct.:',
              datatable: {
                date: 'Dato',
                name: 'Type',
                payment: 'Betaling',
                day: 'Dag',
                netPresentValue: 'Nutidsværdi',
              },
            },
            contractCustomFieldsTab: {
              title: 'Andre informationer',
              buttons: {
                submit: 'Gem',
              },
            },
            contractTasksTab: {
              title: 'Opgaver',
              addTaskModal: {
                addTitle: 'Opret ny opgave',
                editTitle: 'Rediger opgave',
                buttonText: 'Tilføj opgave',
                fields: {
                  task: {
                    name: 'Opgave:',
                    placeholder: 'Vælg venligst en opgave',
                    note: 'Opret ny opgavetype',
                  },
                  due_date: 'Frist:',
                  assignee: {
                    name: 'Ansvarlig:',
                    placeholder: 'Vælg venligst en ansvarlig',
                  },
                },
                actionButtons: {
                  editSubmit: 'Gem',
                  addSubmit: 'Tilføj',
                  cancel: 'Annuller',
                },
              },
              datatable: {
                id: '',
                task_name: 'Opgavenavn',
                assignee: 'Ansvarlig',
                due_date: 'Frist',
                status: 'Status',
                completed: 'Udført',
                notCompleted: 'Ikke udført',
                actions: {
                  name: 'Handlinger',
                  edit: 'Rediger',
                  markAsDone: 'Marker som udført',
                  markAsNotDone: 'Marker som Ikke udført',
                },
              },
            },
          },
        },
      },
      title: 'Kundeoplysninger',
      nameAndAddress: {
        title: 'Navn og adresse',
        info: {
          id: 'Id:',
          firstName: 'Fornavn:',
          company: 'Firma:',
          type: 'Type:',
          cprOrCvr: 'CPR/CVR:',
          address: 'Adresse:',
          city: 'By:',
          economicId: 'Economic Id:',
          lastName: 'Efternavn:',
          email: 'Email:',
          zipCode: 'Postnummer:',
          phoneNumber: 'Telefonnummer:',
        },
      },
      others: {
        title: 'Øvrige',
        info: {
          bankRegistration: 'Reg. nr.:',
          bankAccountNumber: 'Kontonr:',
          paymentTerm: 'Betalingsbetingelser:',
          maritalStatus: 'Civilstatus:',
          occupationType: 'Beskæftigelse:',
          education: 'Uddannelse:',
          educationLevel: 'Uddannelsesniveau:',
          housingType: 'Boligtype:',
          memberOfUnion: 'Fagforeningsmedlem:',
          homeLivingChildren: 'Antal hjemmeboende børn:',
          creditScore: 'Kredit score:',
          driverLicenseFile: 'Evt. kørekort:',
          socialSecurityFile: 'Identifikation på kunde:',
          download: 'Download',
          loading: 'Henter...',
        },
      },
    },
    leasingOffers: {
      title: 'Leasingtilbud',
      tableColumns: {
        id: 'Id',
        brand: 'Mærke',
        model: 'Model',
        payout: 'Udbetaling',
        securityDeposit: 'Depositum',
        monthlyLeasePayments: 'Månedlig leasingydelse',
        contractLengthInMonths: 'Kontraktlængde i måneder',
        residualValue: 'Restværdi',
        activeMonthsAYear: 'Aktive måneder om året',
        actions: 'Handlinger',
      },
    },
    leases: {
      activeLeases: 'Aktive leasingkontrakter',
      historicalLeases: 'Historiske leasingkontrakter',
      tableColumns: {
        leasing_contract: 'Kontraktnr.',
        owner_name: 'Ansvarlig',
        vehicle: 'Køretøj',
        monthly_payment_cents: 'Månedlig ydelse',
        contract_length_in_months: 'Løbetid i måneder',
        termination_date: 'Slut dato',
        state: 'Status',
        actions: 'Handlinger',
      },
    },
    notes: {
      title: 'Noter',
      tableColumns: {
        creator_name: 'Bruger',
        leasing_contract: 'Kontraktnr.',
        message_date: 'Dato',
        text: 'Tekst',
      },
    },
    invoices: {
      title: 'Fakturaer',
      invoiceStatusSelect: {
        showAll: 'Vis alle',
        paid: 'Betalt',
        overdue: 'Overskredet',
        notPaid: 'Ikke betalt',
      },
      tableColumns: {
        economicInvoiceBooked: 'Fakturanr.',
        leasingContract: 'Kontraktnr.',
        netAmountCents: 'Beløb',
        remainderAmountCents: 'Forfalden',
        date: 'Dato',
        dueDate: 'Forfaldsdato.',
        overdueDays: 'Forfalden i dage',
      },
    },
    financialInfo: {
      title: 'Indkomst og formue',
      customerFinancialInfoModal: {
        title: 'Indkomst og formue',
        buttonText: 'Tilføj indtægt, udgift el. andet',
        fields: {
          date: {
            name: 'Dato',
            placeholder: '',
          },
          type: {
            name: 'Type',
            options: {
              income: 'Indkomst',
              expense: 'Udgifter',
              asset: 'Værdier',
              liability: 'Gæld',
            },
          },
          value: {
            name: 'Værdi',
            placeholder: 'Vælg type',
          },
          amount: {
            name: 'Beløb',
            note: 'udgifter og gæld skal påføres et -',
          },
        },
        actionButtons: {
          cancel: 'Annuller',
          submit: 'Gem',
        },
      },
      uploadTaxModal: {
        title: 'Upload årsopgørelse eller lønseddel',
        buttonText: 'Upload årsopgørelse eller lønseddel',
        fields: {
          date: {
            name: 'Dato',
            placeholder: '',
          },
          fileType: {
            name: 'Filtype',
            options: {
              tax_statement: 'Årsopgørelse',
              paycheck: 'Lønseddel',
            },
            placeholder: '',
          },
          file: {
            name: 'Fil',
            placeholder: '',
          },
        },
        actionButtons: {
          cancel: 'Annuller',
          submit: 'Gem',
        },
      },
      assetLiabilityTable: {
        availableDates: 'Tilgængelige datoer',
        previousDates: 'Tidligere indtastninger',
        table: {
          name: 'Navn',
          delete: 'Slet',
          assets: 'Værdier',
          liabilities: 'Gæld',
          assetsAndLiabilities: 'Værdier + gæld',
          incomes: 'Indkomst',
          expenses: 'Udgifter',
          incomeAndExpenses: 'Indkomst + udgifter',
        },
      },
      incomeDocumentsTable: {
        title: 'Dokumentation for indkomst',
        tableColumns: {
          id: 'Id',
          date: 'Dato',
          type: 'Type',
          actions: 'Handlinger',
        },
      },
    },
    ContractTasks: {
      view: {
        title: 'Opgaver',
        filters: {
          status: {
            all: 'Alle',
            completed: 'Udført',
            notCompleted: 'Ikke udført',
          },
        },
        datatable: {
          id: 'ID',
          contract_id: 'Kontrakt',
          assignee: 'Ansvarlig',
          task: 'Opgavenavn',
          due_date: 'Frist',
          status: {
            name: 'Status',
            completed: 'Udført',
            notCompleted: 'Ikke udført',
          },
          actions: {
            name: 'Handlinger',
            markAsDone: 'Marker som udført',
            markAsNotDone: 'Marker som Ikke udført',
            edit: 'Rediger',
            openContract: 'Åben kontrakt',
          },
        },
      },
    },
    Contracts: {
      view: {
        title: 'Kontrakt',
        buttons: {
          newContract: 'Ny kontrakt',
          filters: 'Filtre',
          clearFilters: 'Nulstil filtre',
          company: 'Firma',
          showAll: 'Vis alle',
          type: 'Type',
          status: 'Status',
          vat: 'Moms',
          EU_vat: 'EU moms',
        },
        datatable: {
          leasing_contract_id: 'Kontraktnr.',
          organization_name: 'Firmanavn',
          owner_name: 'Ansvarlig',
          leasing_type: 'Leasingtype',
          contract_type: 'Kontrakttype',
          customer_name: 'Navn',
          company_name: 'Firma',
          vehicle: 'Køretøj',
          vehicle_serial_number: 'Stelnr.',
          vehicle_plate_number: 'køretøjets pladenummer',
          termination_date: 'Slut dato',
          contract_value: 'Kontraktværdi',
          state: 'Status',
          contract_completion_status: 'Opgaver',
          actions: 'Rediger',
        },
      },
      newContractPage: {
        steps: {
          titles: {
            contract: 'Kontrakt',
            newContract: 'Ny kontrakt',
            editContract: 'Rediger Kontrakt',
          },
          marketPriceCalculatorStep: {
            title: 'Handelsprisberegner',
          },
          leasingCalculatorStep: {
            title: 'Leasingberegner',
          },
          vehicleInformationStep: {
            title: 'Køretøjsoplysninger',
            viewVehicleInfo: {
              others: 'Øvrige',
              id: 'Id:',
              type: 'Type:',
              brand: 'Mærke:',
              model: 'Model:',
              usedVat: 'Brugtmoms:',
              yes: 'Ja',
              no: 'Ingen',
              date_of_first_registration: 'Første registreringsdato:',
              serial_number: 'Stelnr.',
              vehicle_plate_number: 'køretøjets pladenummer',
              fuel: 'Brændstof:',
              engine: 'Motor:',
              carType: 'Biltype:',
              transmission: 'Transmission:',
              version: 'Udstyrsvariant:',
              ekstra: 'Ekstraudstyr:',
              marketValue: 'Markedsværdi:',
              mileage: 'Kørte km:',
              changeVehicleModal: {
                title: 'Skift køretøj',
                buttonText: 'Skift køretøj',
                vehicle: 'Køretøj',
                please_choose_the_new_vehicle: 'Vælg det ønskede køretøj',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
              updateVehiclePlateNumberModal: {
                title: 'Opdater køretøjs registreringsnummer',
                vehicle_plate_number: 'køretøjets pladenummer',
                vehicle: 'Køretøj',
                actionButtons: {
                  cancel: 'Annuller',
                  submit: 'Gem',
                },
              },
            },
            addVehicle: {
              selectVehicle: 'Vælg Køretøj',
              createVehicle: 'Opret Køretøj',
              vehicle: 'køretøj',
              brand: 'Mærke',
              model: 'Model',
              usedVat: 'Brugtmoms',
              date_of_first_registration: 'Første registreringsdato',
              serial_number: 'Stelnr.',
              fuel: 'Brændstof',
              engine: 'Motor',
              carType: 'Biltype',
              transmission: 'Transmission',
              version: 'Udstyrsvariant',
              ekstra: 'Ekstraudstyr',
              marketValue: 'Markedsværdi',
              mileage: 'Kørte km.',
              condition: 'Stand',
              euro_ncap: '5-stjernet NCAP markering',
              abs: 'ABS',
              customs_inpsections: 'Toldsynet',
              price_as_new_cents: 'Nypris',
              km_per_litre: 'km/l',
              num_airbags: 'Antal airbags',
              num_seat_belts: 'Antal selealarmer',
              num_integrated_children_seats: 'Antal integrerede børnesæder',
            },
          },
          customerInformationStep: {
            title: 'Kundeoplysninger',
            chooseCustomer: 'Vælg kunde',
            createCustomer: 'Opret kunde',
            customer: 'Kunde',
            private: 'Privat',
            company: 'Firma',
            first_name: 'Fornavn',
            last_name: 'Efternavn',
            email: 'Email',
            contactPersonFirstname: 'Kontaktperson fornavn',
            contactPersonLastname: 'Kontaktperson efternavn',
            cpr_number: 'CPR',
            cvr_number: 'CVR',
            address: 'Adresse',
            zip_code: 'Postnummer',
            city: 'By',
            telephone: 'Telefonnummer',
            bank_registration: 'Reg. nr.',
            bank_account_number: 'Kontonr.',
            payment_term: {
              name: 'Betalingsbetingelser',
              placeholder: 'Vælg betalingsbetingelser',
            },
            viewCustomerInfo: {
              id: 'Id:',
              type: 'Type:',
              first_name: 'Fornavn:',
              last_name: 'Efternavn:',
              email: 'Email:',
              cpr_cvr_number: 'CPR/CVR:',
              address: 'Adresse:',
              zip_code: 'Postnummer:',
              city: 'By:',
              company: 'Firma:',
              telephone: 'Telefonnummer:',
            },
          },
          contractCreatedStep: {
            title: 'Kontrakt oprettet',
            print: 'Print',
            sendToCustomer: 'Send til kunden',
            goToCustomer: 'Gå til kunden',
            goToPrivateCustomer: 'Gå til privatkunden',
            goToCompanyCustomer: 'Gå til erhvervskunden',
            goToContracts: 'Gå til kontrakter',
          },
          stepsActions: {
            back: 'Tilbage',
            next: 'næste',
            skip: 'Spring over',
            create: 'Opret',
            edit: 'Rediger',
            print: 'Print',
            save: 'Gem',
            send: 'Send',
            inquire: 'Forespørg',
          },
        },
      },
      editContractPage: {
        tabs: {
          contractTab: {
            title: 'Kontrakt',
            actionsMenu: {
              title: 'Handlinger',
              cancelContract: 'Annuller kontrakt',
              restoreContract: 'Genåben kontrakt',
              copyContract: 'Lav tilbud',
            },
            tasksTitle: 'Opgavelisten',
            addSecondaryCustomerModal: {
              title: 'Vælg eller Opret kunde',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            selectCustomerToDeactivateModal: {
              title: 'Vælg kunde',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            moveOrSellVehicleModal: {
              title: 'Sælg eller flyt køretøj?',
              sell_vehicle: 'Sælg køretøj',
              move_vehicle: 'Flyt køretøj',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Næste',
              },
            },
            requestEUvatRefundModal: {
              title: 'Annmod om EU-moms refundering',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Marker som udført',
              },
            },
            contractCreationModal: {
              title: 'Udskriv kontrakt',
              downloadDreaft: 'Dan kontrakt',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Marker som udført',
              },
            },
            contractUploadModal: {
              title: 'Upload kontrakt',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Upload underskrevet kontrakt',
              },
            },
            insuranceTaskModal: {
              title: 'Opret forsikring',
              createInsurance: 'Opret forsikring',
              customerhasInsuredTheVehicle: 'Kunde har selv forsikret køretøjet',
              copyToClipboard: 'Kopier til udklipsholder',
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Marker som udført',
              },
            },
            createUpfrontPaymentsModal: {
              title: 'Opret førstegangsbetalinger.',
              fields: {
                date: 'Dato',
                payment_term_number: {
                  name: 'Betalingsbetingelser',
                  placeholder: 'Vælg betalingsbetingelser',
                },
              },
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            creditApproveModal: {
              title: 'Kreditgodkendelse',
              fields: {
                customer: 'Kunde',
                status: {
                  name: 'Status',
                  placeholder: 'Vælg status',
                  approved: 'Godkendt',
                  denied: 'Afvist',
                },
                message_text: 'Tekst',
              },
              assetsLiabilitySummary: {
                title: 'Oversigt over aktiver/passiver',
                addNewCreditNote: 'Hvis du ønsker at tilføje nye kreditinformationer eller se specifikationer.',
                goToFinancialInfo: 'Gå til finannciel info',
                dates: 'Datoer',
                assets_liabilites: 'Aktiver - Passiver',
                income_expenses: 'Indkomst - Udgifter',
                customerIncomeDocuments: 'Dokumentation for indkomst',
              },
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Gem',
              },
            },
            generatePaymentTaskModal: {
              title: 'Levering og oprettelse af md. betalinger',
              fields: {
                start_date: 'Start dato',
                delivery_date: 'Leveringsdato',
                process_date: 'Effektueringsdato',
                final_registration_tax: 'Endelig registreringsafgift',
                full_payments: 'Opret månedlige betalinger som fulde betalinger fra startdatoen.',
              },
              actionButtons: {
                cancel: 'Annuller',
                submit: 'Tilføj',
              },
            },
          },
          paymentsTab: {
            title: 'Betalinger',
            addPaymentModal: {
              title: 'Tilføj betaling',
              buttonText: 'Tilføj betaling',
              fields: {
                payment_type: 'Betalingstype',
                date: 'Dato',
                customer: 'kunde',
                tax_vat_cents: 'Beløb i kr. ekskl. moms',
                vat_cents: 'Moms',
                from_date: 'Fra dato',
                to_date: 'Til dato',
              },
              actionButtons: {
                submit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
            generatePayment: {
              title: 'Posteringer (entries)',
              buttonText: 'Dan månedlige betalinger',
              fields: {
                payment_type: 'Betalingstype',
                start_date: 'Start dato',
                delivery_date: 'Leveringsdato',
                monthly_payment: 'Månedlig beløb (inkl. moms)',
                termination_date: 'Slut dato',
                process_date: 'Effektueringsdato',
                to_date: 'Til dato',
                full_payments: 'Opret månedlige betalinger som fulde betalinger fra startdatoen.',
              },
              actionButtons: {
                submit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
            addInvoiceModal: {
              title: 'Opret og bogfør fakturaerne for betalingerne',
              buttonText: 'Opret faktura',
              invoicesNote:
                'BEMÆRK: Alle fakturaerne vil blive oprettet på den valgte bogføringsdato og med opkrævningsdatoen, der følger betalingsbetingelserne.',
              fields: {
                payment_book_date: 'Betalinger til og med',
                due_date: 'Bogføringsdato',
                payment_term_number: {
                  name: 'Betalingsbetingelser',
                  placeholder: 'Vælg betalingsbetingelser',
                },
              },
              actionButtons: {
                submit: 'Opret og bogfør',
                cancel: 'Annuller',
              },
            },
            datatable: {
              Id: 'Id',
              customer_name: 'Navn',
              product_name: 'Produkt',
              payment_date: 'Dato',
              text: 'Tekst',
              booked: 'Bogført',
              payment_excl_tax_vat_cents: 'Beløb i kr. ekskl. moms',
              vat_cents: 'Moms',
              vat_deduction_cents: 'Momsfradrag',
              payment_incl_tax_vat_cents: 'Beløb i alt',
              actions: 'Handlinger',
            },
          },
          notesTab: {
            title: 'Noter',
            addNoteModal: {
              title: 'Tilføj note',
              buttonText: 'Tilføj note',
              fields: {
                message_date: 'Dato',
                customer: 'Kunde',
                message_text: 'Tekst',
              },
              actionButtons: {
                submit: 'Gem',
                cancel: 'Annuller',
              },
            },
            datatable: {
              customer_name: 'Navn',
              creator_name: 'Bruger',
              message_date: 'Dato',
              text: 'Tekst',
            },
          },
          transactionsTab: {
            title: 'Transaktioner',
            buttons: {
              importCashEntries: 'Bogfør i e-conomic',
              runInterestMonthly: 'Tilskriv rente manuelt',
              closeContract: 'Afslut kontrakt',
            },
            addTransactionsModal: {
              title: 'Tilføj transaktioner',
              buttonText: 'Tilføj transaktioner',
              fields: {
                selectTemplate: {
                  name: 'Vælg skabelon',
                  placeholder: 'Vælg transaktionsskabelon',
                },
                saveTemplate: 'Gem skabelonn',
                templateName: 'Skabelonsnavn',
                transaction_date: 'Dato',
                transaction_type: 'Transaktionstype',
                amount_cents: 'Beløb',
                vat_code: 'Momskode',
                vehicle: 'Køretøj',
                sum_of_transactions_note: 'Summen for transaktionen er',
                transaction_record_files_placeholder: 'Klik eller træk filer hertil for at uploade',
                sellVehicleModal: {
                  payment_product: 'Betalingstype',
                  date: 'Dato',
                  customer: {
                    name: 'Kunde',
                    placeholder: 'Vælg kunde',
                    create_new_customer_note: 'Opret ny kunde',
                  },
                  payment_term: 'Betalingsbetingelser',
                  payment_excl_tax_vat: 'Beløb i kr. ekskl. moms',
                  payment_vat: 'Moms',
                  transaction_amount: 'Kontraktværdien nedskrives med',
                },
              },
              actionButtons: {
                submit: 'Gem',
                cancel: 'Annuller',
              },
            },
            datatable: {
              transaction_date: 'Dato',
              transaction_set: 'Transaktions id',
              transaction_type: 'Transaktionstype',
              account_number: 'Konto',
              amount_cents: 'Beløb',
              contract_value: 'Kontraktværdi',
              booked: 'Bogført',
              vehicle: 'Køretøj',
              vat_code: 'Momskode',
              download_document: 'Bilag',
            },
          },
          estimatedPaymentsTab: {
            title: 'Folsomhedsberegninger',
            datatable: {
              estimated_monthly_payment_id: 'Id',
              estimated_registration_tax_lower_cents: 'Registreringsafgiften mindre end',
              estimated_monthly_payment_cents: 'Leasingydelse inkl. moms',
            },
          },
          amortizationsTab: {
            title: 'Amortisering',
            buttons: {
              postAmortizations: 'Dan amortisering',
              postRepaymentTransactions: 'Dan afdragstransaktioner',
            },
            datatable: {
              contract_payment_id: 'Betalingsid',
              from_date: 'Fra dato',
              to_date: 'Til dato',
              payment_excl_tax_vat: 'Betaling',
              opening_value: 'Primoværdi',
              interest: 'Rente',
              repayment: 'Afdrag',
              ending_value: 'Ultimoværdi',
              interest_days: 'Rentedage',
              interest_of_month: 'Rente for perioden',
              booked: 'Bogført',
            },
          },
          customerInformationTab: {
            title: 'Kundeoplysninger',
            customerTab: {
              title: 'Kundedetaljer',
              changeCustomerModal: {
                title: 'Ændre kunde(r)',
                buttonText: 'Ændre kunde(r)',
                tabs: {
                  primary: {
                    title: 'Primær',
                    subtitle: 'Nuværende kunde',
                    fields: {
                      end_date: 'Slut dato',
                      newCustomer: {
                        title: 'Ny kunde',
                      },
                    },
                    submitButton: 'Gem',
                  },
                  secondary: {
                    title: 'Sekundær',
                    subtitle: 'Sekundær bruger',
                    fields: {
                      end_date: 'Slut dato',
                      newSecondaryUser: {
                        title: 'Ny sekundær bruger',
                        chooseCustomer: 'Vælg kunde',
                        start_date: 'Start dato',
                      },
                    },
                    submitButton: 'Gem',
                  },
                  guarantor: {
                    title: 'Kautionist',
                    subtitle: 'Kautionist',
                    fields: {
                      new_guarantor: 'Tilføj kautionist',
                      start_date: 'Start dato',
                      currentGuarantorCustomer: {
                        title: 'Kautionist',
                        end_date: 'Slut dato',
                      },
                    },
                    submitButton: 'Gem',
                  },
                  historic: {
                    title: 'Historisk',
                    subtitle: 'Historik',
                    table: {
                      name: 'Navn',
                      type: 'Type',
                      from_date: 'Fra dato',
                      to_date: 'Til dato',
                    },
                  },
                },
                actionButtons: {
                  cancel: 'Annuller',
                },
              },
            },
            incomeAndExpensesTab: {
              title: 'indkomst og formue',
            },
            invoicesTab: {
              title: 'Fakturaer',
            },
          },
          vehicleTab: {
            title: 'Køretøjsoplysninger',
            selectedInvoiceStatus: {
              showAll: 'Vis alle',
              paid: 'Betalt',
              overdue: 'Overskredet',
              notPaid: 'Ikke betalt',
            },
          },
          documentsTab: {
            title: 'Dokumenter',
            buttons: {
              generateDraftContract: 'Dan kontrakt',
              deleteDraftContract: 'Slet dannet kontrakt',
              downloadSignedContract: 'Download underskrevet kontrakt',
              deleteSignedContract: 'Slet underskrevet kontrakt',
              uploadSignedContract: 'Upload underskrevet kontrakt',
            },
          },
          mileageSplitDetailsTab: {
            title: 'Kørselsopgørelse',
            date_of_statement: 'Opgørelsesdato',
            populateTable: 'Hent fordeling',
            createSplitPayments: 'Dan udligningsbetalinger.',
            table: {
              columns: {
                private: 'Privat',
                company: 'Erhverv',
                total: 'Total',
              },
              rows: {
                mileage: 'Kilometer',
                split: 'Kørselsfordeling',
                charged_per_customer: 'Opkrævet beløb',
                actual_payments: 'Beløb til opkrævning, fordelt efter faktiske km.',
                due_owed_by_customer: 'Beløb til udligning.',
              },
            },
          },
          mileageTableTab: {
            title: 'Kørselsoversigt',
            datatable: {
              customer_id: 'Kundenr.',
              from_date: 'Fra dato',
              to_date: 'Til dato',
              trip_type: 'Kørselstype',
              kilometer: 'Kilometer',
              actions: 'Handlinger',
            },
            buttons: {
              importContractMileage: 'Upload kørselsdata',
              downloadContractMileage: 'Download kørselsdata',
              updateMileage: 'Opdater kørselsdata',
            },
          },
          internalRateTab: {
            title: 'Nøgletal',
            internalRateInPercent: 'Intern rente i pct.:',
            datatable: {
              date: 'Dato',
              name: 'Type',
              payment: 'Betaling',
              day: 'Dag',
              netPresentValue: 'Nutidsværdi',
            },
          },
          contractCustomFieldsTab: {
            title: 'Andre informationer',
            buttons: {
              submit: 'Gem',
            },
          },
          contractTasksTab: {
            title: 'Opgaver',
            addTaskModal: {
              addTitle: 'Opret ny opgave',
              editTitle: 'Rediger opgave',
              buttonText: 'Tilføj opgave',
              fields: {
                task: {
                  name: 'Opgave:',
                  placeholder: 'Vælg venligst en opgave',
                  note: 'Opret ny opgavetype',
                },
                due_date: 'Frist:',
                assignee: {
                  name: 'Ansvarlig:',
                  placeholder: 'Vælg venligst en ansvarlig',
                },
              },
              actionButtons: {
                editSubmit: 'Gem',
                addSubmit: 'Tilføj',
                cancel: 'Annuller',
              },
            },
            datatable: {
              id: '',
              task_name: 'Opgavenavn',
              assignee: 'Ansvarlig',
              due_date: 'Frist',
              status: 'Status',
              completed: 'Udført',
              notCompleted: 'Ikke udført',
              actions: {
                name: 'Handlinger',
                edit: 'Rediger',
                markAsDone: 'Marker som udført',
                markAsNotDone: 'Marker som Ikke udført',
              },
            },
          },
        },
      },
    },
    CalculationResult: {
      monthlyPrice: 'Månedlig pris',
      DKK: 'DKK',
      Privat_DKK: 'Privat(DKK)',
      Erhverv_DKK: 'Erhverv(DKK)',
      total: 'I alt',
      firstPayment: 'Første betaling',
      otherFigures: 'Andre tal',
      kilometerLimit: 'Kilometer begrænsning pr. år.',
      pricePerAdditionalKM: 'Pris pr. overkørte kilometer',
      depreciationPercent: 'Afskrivning over perioden i procent',
      vatDeductionPerMonth: 'Momsfradrag pr. måned',
      vatDeductionDownPayment: 'Momsfradrag i udbetalingen',
      salvageValue: 'Restværdi*',
      totalInitialCosts: 'Samlede stiftelsesomk.',
      totalLoanAmountInclCost: 'Lånebeløb inkl. omk.',
      totalInterestAmount: 'Samlede renteomk.',
      totalCreditAmount: 'Samlede kreditomk.',
      totalRePayment: 'Samlet tilbagebetaling',
      interestRate: 'Debitorrente p.a (variabel)',
      annualPercentageRate: 'Årlig omk. i (pct.)',
      earnings: 'Indtjening',
      totalPayments: 'Ydelser, restværdi',
      profit: '- Heraf avance på køretøj',
      totalRevenue: 'Omsætning fra andre produkter',
      totalCostProducts: 'Omkostninger',
      netInvestment: 'Nettoinvestering',
      deviationFromMinimumProfit: 'Afvigelse fra minimum indtjening',
      otherThings: 'Andet',
      internalRate: 'Intern rente (pct.)',
      downPaymentMaxValue: 'Maksimal selvfinansiering',
      taxForPeriod: 'Forholdsmæssig registreringsafgift:',
      selfFinancing: 'Selvfinansiering (pct.)',
      performanceAfter: 'Ydelse efter',
      mdr: 'mdr.:',
      allAmountsNote: 'Alle beløb er inkl. moms med undtagelse af beløb mærket med *',
      internalRateModal: {
        title: 'Nogletal',
        datatable: {
          date: 'Dato',
          payment: 'Betaling',
          day: 'Dag',
          interest_days: 'Rentedage',
          interest_pct: 'Rente pct',
          interest: 'Rente',
          repayment: 'Afdrag',
          end_value: 'Slutværdi',
          netPresentValue: 'Nutidsværdi',
        },
        actionButtons: {
          cancel: 'Annuller',
        },
      },
      costProductsModal: {
        title: 'Omkostninger',
        datatable: {
          name: 'Navn',
          amount_as_contract_length: 'Pris ekskl. moms',
        },
        actionButtons: {
          cancel: 'Annuller',
        },
      },
      productsRevenueModal: {
        title: 'Omsætning fra andre produkter',
        datatable: {
          name: 'Navn',
          amount_as_contract_length: 'Pris ekskl. moms',
        },
        actionButtons: {
          cancel: 'Annuller',
        },
      },
    },
    LeasingCalculator: {
      leasing_type: 'Leasingtype',
      contract_type: 'Kontrakttype',
      private_split_percent: 'Privat andel i pct.',
      company_split_percent: 'Erhverv andel i pct.',
      vehicle_type: 'Aktiv',
      vehicles_first_registration: 'Første indregistrering',
      expected_payout_date: 'Forventet effektueringsdato',
      vehicle_used_vat: 'Brugtmoms',
      EU_vat: 'EU moms',
      price_of_vehicle: 'Pris',
      loan_amount: 'Lånebeløb',
      estimated_tax: 'Forventet registreringsafgift',
      contract_length_in_months: 'Måneder',
      down_payment: 'Udbetaling',
      down_payment_exclude_vat: 'Udbetaling',
      buttons: {
        addAdditionalProduct: 'Tilføj yderligere produkter',
        addCostProduct: 'Tilføj udgift',
        extraFieldsButton: 'Ekstra indstillinger',
      },
      addAdditionalProductModal: {
        title: 'Tilføj yderligere produkter',
        fields: {
          payment_type: 'Betalingstype',
          payment_excl_tax_vat_cents: 'Beløb i kr. ekskl. moms',
          vat_cents: 'Moms',
          cost_price_excl_vat_cents: 'Kostpris ekskl. moms',
        },
        actionButtons: {
          cancel: 'Annuller',
          submit: 'Tilføj',
        },
      },
      addCostProductModal: {
        title: 'Tilføj udgift',
        fields: {
          payment_type: {
            name: 'Betalingstype',
            placeholder: 'Vælg betalingstype',
          },
          payment_excl_tax_vat_cents: 'Beløb i kr. ekskl. moms',
        },
        actionButtons: {
          cancel: 'Annuller',
          submit: 'Tilføj',
        },
      },
      commission_exclude_vat: 'Provision*',
      profit: 'Avance på køretøj*',
      final_registration_tax: 'Endelig registreringsafgift*',
      deposit: 'Depositum',
      weighted_rate: 'Årlig rente (%)',
      monthly_payment: 'Månedlig leasingydelse*',
      salvage_value: 'Restværdi*',
      kilometer_limit: 'Kilometer begrænsning pr. år.',
      price_per_additional_km: 'Pris pr. overkørte kilometer',
      private_tax: 'Beskatningsgrundlag',
    },
    MarketPriceCalculator: {
      vehicle_info: 'Køretøjsinformationer:',
      calculationOfMarketPrice: {
        title: 'Beregning af handelspris',
        fields: {
          source_of_add: 'Kilde',
          model: 'Mærke og model',
          date_of_first_registration: '1. Indregistrering',
          kilometer: 'Km-stand',
          price: 'Pris',
          file_documents: 'Dokumentation',
        },
        average_of_vehicles_for_comparison: 'Gennemsnit for sammenlignelige biler',
      },
      deductions: {
        title: 'Fradrag',
        deduction_for_add: 'Annoncefradrag',
        deduction_for_condition: 'Tilstandsfradrag',
        deduction_addition_for_km: 'Tillæg/Fradrag for km-stand',
        deduction_addition_for_age: 'Tillæg/Fradrag for årgang',
        market_price_for_vehicle: 'Handelspris for køretøjet',
        registration_tax: 'Registreringsafgift',
      },
    },
    Offers: {
      view: {
        title: 'Tilbud',
        buttons: {
          newDeal: 'Nyt tilbud',
          addOffersToCustomer: 'Tilføj valgte tilbud til ny kunde',
          filters: 'Filtre',
          clearFilters: 'Nulstil filtre',
          company: 'Firma',
          showAll: 'Vis alle',
          type: 'Type',
        },
        datatable: {
          offerId: 'Tilbudsnr.',
          organization_name: 'Firmanavn',
          customer_name: 'Navn',
          company_name: 'Firma',
          leasing_type: 'Leasingtype',
          contract_type: 'Kontrakttype',
          customer_email: 'Email',
          vehicle_brand_name: 'Mærke',
          vehicle_model: 'Model',
          down_payment_cents: 'Udbetaling',
          salvage_value_cents: 'Restværdi',
          add_to_frontpage: 'Vis på forside',
          actions: {
            name: 'Handlinger',
            options: {
              requestContract: 'Forespørg',
              edit: 'Rediger',
              sendToCustomer: 'Send til kunden',
              print: 'Print',
            },
          },
        },
        addOffersToCustomerModal: {
          title: 'Tilføj valgte tilbud til ny kunde',
          fields: {
            chooseCustomer: 'Vælg kunde',
            customer: 'Kunde',
            createCustomer: 'Opret kunde',
            private: 'Privat',
            company: 'Firma',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            email: 'Email',
            contactPersonFirstname: 'Kontaktperson fornavn',
            contactPersonLastname: 'Kontaktperson efternavn',
          },
          actionButtons: {
            submit: 'Gem',
            cancel: 'Annuller',
          },
        },
        enquireForContractModal: {
          title: 'Forespørg om kontrakt',
          contractInfo: {
            title: 'Contract Info',
            fields: {
              firstName: 'Fornavn',
              lastName: 'Efternavn',
              email: 'Email',
              company: 'Firma',
              address: 'Adresse',
              zip_code: 'Postnummer',
              city: 'By',
              telephone: 'Telefonnummer',
              cpr_number: 'CPR',
              cvr_number: 'CVR',
              bank_registration: 'Reg. nr.',
              bank_account_number: 'Kontonr.',
              payment_term: {
                name: 'Betalingsbetingelser',
                placeholder: 'Vælg betalingsbetingelser',
              },
              engine: 'Motor',
              car_type: 'Biltype',
              version: 'Udstyrsvariant',
              vehicle_market_value: 'Markedsværdi',
              vehicle_market_value_year: 'Markedsværdi om et år',
              vehicle_mileage: 'Kørte km',
              ekstra: 'Ekstraudstyr',
              fuel: 'Brændstof',
              transmission: 'Transmission',
            },
          },
          attachFiles: {
            title: 'Vædhæft filer',
            fields: {
              social_security_file: 'Identifikation på kunde',
              driver_license_file: 'Evt. kørekort',
              date: 'Date',
              documentType: 'Dokument type',
              documentFile: 'Dokument fil',
            },
          },
          actionButtons: {
            submit: 'Gem',
            cancel: 'Annuller',
          },
        },
      },
      add: {
        steps: {
          titles: {
            deals: 'Tilbud',
            newOffer: 'Nyt tilbud',
            editOffer: 'Rediger tilbud',
            editOfferNo: 'Rediger Tilbudnr.',
          },
          marketPriceCalculatorStep: {
            title: 'Handelsprisberegner',
          },
          leasingCalculatorStep: {
            title: 'Leasingberegner',
          },
          vehicleAndCustomerStep: {
            title: 'Køretøj & kunde',
            dealsVehicle: {
              vehicle_make: 'Mærke',
              vehicle_model: 'Model',
            },
            dealsCustomer: {
              chooseCustomer: 'Vælg kunde',
              createCustomer: 'Opret kunde',
              customer: 'Kunde',
              private: 'Privat',
              company: 'Firma',
              first_name: 'Fornavn',
              last_name: 'Efternavn',
              email: 'Email',
              telephone: 'Telephone',
              contact_person_first_name: 'Kontaktperson fornavn',
              contact_person_last_name: 'Kontaktperson efternavn',
            },
          },
          offerCreatedStep: {
            title: 'Tilbud oprettet',
            print: 'Print',
            sendToCustomer: 'Send til kunden',
            goToCustomer: 'Gå til kunden',
            goToDeals: 'Gå til leasingtilbud',
          },
          stepsActions: {
            back: 'Tilbage',
            next: 'næste',
            skip: 'Spring over',
            createOffer: 'Opret tilbud',
            print: 'Print',
            save: 'Gem',
            send: 'Send',
            inquire: 'Forespørg',
          },
        },
      },
    },
    Roles: {
      view: {
        title: 'roller',
        buttons: {
          add: 'Tilføj ny rolle',
          edit: 'Rediger',
          delete: 'Delete',
        },
        datatable: {
          id: 'Id',
          name: 'Navn',
          actions: {
            name: 'Handlinger',
            editbutton: 'Rediger',
            deletebutton: 'Delete',
          },
        },
        deleteModal: {
          title: 'Slet rolle',
          fields: {
            moveToRole: {
              name: 'Flyt til rolle:',
              placeholder: 'Vælg rolle...',
            },
          },
          actionButtons: {
            submit: 'Gem',
            cancel: 'Annuller',
          },
        },
      },
      add: {
        breadcrumb: {
          addTitle: 'tilføj',
          editTitle: 'rediger',
          rolesTitle: 'roller',
        },
        permissions: 'Tilladelser',
        fields: {
          name: {
            name: 'Navn:',
            placeholder: 'Navn',
          },
        },
        tabs: {
          roleBasicDetails: 'Basis-tilladelser',
          moduleFields: 'Input-tilladelser',
        },
        actionButtons: {
          addSubmit: 'Gem',
          editSubmit: 'Gem',
        },
      },
    },
    CLIENTPROFILE: {
      GENERAL: {
        SOCIAL: 'Social',
        CONTACT: 'Contact',
        CURRENTADDRESS: 'Current Address',
        UPLOAD_MEDIA: 'Upload Media',
        DROP_MEDIA: 'Drop the media',
        GALLERY: 'Gallery',
        CHOOSEFILE: 'Choose File',
        EDITCLIENTTITLE: 'Edit Client Profile',
        ADDCLIENTTITLE: 'Create a New Client Profile',
        USERS: 'Users',
        CLIENTS: 'Clients',
        IMAGE_GALLERY: 'Image Gallery',
        DESCRIPTION: 'Description',
        CAMPAIGNS: 'Campaign',
        EDIT_PROFILE: 'Edit Profile',
        DELETE_MSG: 'Are you sure you want to delete ',
        EMPTY_GALLERY: 'No Media Available',
      },
      FIELDS: {
        NAME: {
          NAME: 'Name',
          PLACEHOLDER: 'Name',
        },
        SURNAME: {
          NAME: 'Surname',
          PLACEHOLDER: 'Surname',
        },
        COMPANY: {
          NAME: 'Company Name',
          PLACEHOLDER: 'Company Name',
        },
        POSITION: {
          NAME: 'Position',
          PLACEHOLDER: 'Position',
        },
        COUNTRY: {
          NAME: 'Country',
          PLACEHOLDER: 'Search for a Country',
        },
        CLIENTEMAIL: {
          NAME: 'Client Email',
          PLACEHOLDER: 'Your Email',
          Reporting: 'Reporting',
          BrandAnalytics: 'Brand Analytics',
          MarketAnalytics: 'Market Analytics',
          TalentAnalytics: 'Talent Analytics',
        },
        TWITTER: {
          NAME: 'Twitter',
          PLACEHOLDER: 'Twitter',
        },
        INSTAGRAM: {
          NAME: 'Instagram',
          PLACEHOLDER: 'Instagram',
        },
        LINKEDIN: {
          NAME: 'LinkedIn Profile',
          PLACEHOLDER: 'LinkedIn',
        },
        FACEBOOK: {
          NAME: 'Facebook',
          PLACEHOLDER: 'Facebook',
        },
        COMPANYDESCRIPTION: {
          NAME: 'Company Description',
          PLACEHOLDER: 'Company Description',
        },
        COMPANYWEBSITE: {
          NAME: 'Company Website',
          PLACEHOLDER: 'Company Website',
        },
        COMPANYCAREERPAGE: {
          NAME: 'Company Career Page Link',
          PLACEHOLDER: 'Company Career Page Link',
        },
        PROFILE_PICTURE: {
          NAME: 'Profile Picture',
          PLACEHOLDER: 'Choose Profile Picture',
        },
        CLIENT_PROFILE_PIC: {
          NAME: "Client's Profile Picture",
          PLACEHOLDER: 'Choose Profile Picture',
        },
        CLIENTPASSWORD: {
          NAME: 'Client Password',
          PLACEHOLDER: "Your Client's Login Password",
        },
        CONFIRMCLIENTPASSWORD: {
          NAME: 'Confirm Client Password',
          PLACEHOLDER: "Your Client's Login Password",
        },
      },
      VALIDATIONS: {
        NAME: 'Name is required',
        EMAIL: 'Email is required',
        PASSWORD: 'Password is required',
        PASSWORDLENGTH: 'Password should be at leaset 8 characters',
        PASSWORDMATCH: "Passwords aren't matched",
        CLIENTNAME: 'Client name is required',
        SURNAME: 'Surname is required',
        COMPANY: 'Company is required',
        POSITION: 'Position is required',
        COUNTRY: 'Country is required',
        CLIENTEMAIL: "Client's email is required",
        CLIENTPASSWORD: "Client's password is required",
      },
    },
    CAMPAIGN: {
      GENERAL: {
        CAMPAIGNS: 'Campaigns',
        CAMPAIGNS_LIST: 'Campaigns List',
        CRETAE_NEW_CAMPAIGN: 'Create New Campaign',
      },
      FIELDS: {
        TITLE: {
          NAME: 'Title',
          PLACEHOLDER: 'Title',
        },
        GOAL: {
          NAME: 'Goal',
          PLACEHOLDER: 'Goal',
        },
        REACH: {
          NAME: 'REACH',
          PLACEHOLDER: 'REACH',
        },
        START_DATE: {
          NAME: 'Start Date',
          PLACEHOLDER: 'Start Date',
        },
        END_DATE: {
          NAME: 'End Date',
          PLACEHOLDER: 'End Date',
        },
        LOCATION: {
          NAME: 'Location',
          PLACEHOLDER: 'Location',
        },
        AGE: {
          NAME: 'Age',
          PLACEHOLDER: 'Age',
        },
        FROM: {
          NAME: 'From',
        },
        TO: {
          NAME: 'To',
        },
        DEMOGRAPHICS: {
          NAME: 'Demographics - Interests - Behaviors',
          PLACEHOLDER: 'Demographics - Interests - Behaviors',
        },
        PLACEMENETS: {
          NAME: 'Placements',
          PLACEHOLDER: 'Placements',
        },
        GENDER: {
          GENDER: 'Gender',
          MALE: 'Male',
          FEMALE: 'Female',
          ALL: 'All',
        },
        LOCATIONS: {
          NAME: 'Locations',
          PLACEHOLDER: 'Locations',
        },
        LANGUAGE: {
          NAME: 'Language',
          PLACEHOLDER: 'Language',
        },
        PRIMARY_TEXT: {
          NAME: 'Primary text',
          PLACEHOLDER: 'Primary text',
        },
        HEADLINE: {
          NAME: 'Headline',
          PLACEHOLDER: 'Headline',
        },
        DESCRIPTION: {
          NAME: 'Description',
          PLACEHOLDER: 'Description',
        },
        URL: {
          NAME: 'Url',
          PLACEHOLDER: 'Url',
        },
        NAME: {
          NAME: 'Name',
          PLACEHOLDER: 'Name',
        },
        BUDGET: {
          NAME: 'Budget',
          PLACEHOLDER: 'Budget',
        },
        SPECIAL_CATEGORY: {
          NAME: 'Ads Special category',
        },
      },
      VALIDATIONS: {
        AUDIENCE_NAME: 'Audience Name is required',
        TITLE: 'Campaign title is required',
        TITLE_LENGTH: 'Campaign title should be between 3 and 200 characters.',
        AUDIENCE_NAME_LENGTH: 'Audience name should be between 3 and 200 characters.',
        GOAL: 'Goal is required',
        BUDGET: 'Ad Type is required',
        LOCATION: 'Location is required',
        LANGUAGE: 'Language is required',
        START_DATE: 'Start date is required',
        SATRT_DATE_INVALID: 'The start date must be a date after or equal to today',
        AGE_RANGE: 'Please choose the age range',
        WHITE_SPACE: 'This field should not start with empty space',
      },
      DATATABLE: {
        HEADERS: {
          ID: 'ID',
          START_DATE: 'Start Date',
          END_DATE: 'End Date',
          TITLE: 'Title',
          BUDGET: 'Budget',
          GOAL: 'Goal',
          AUDIENCE_NAME: 'Audience Name',
          STATUS: 'Status',
        },
      },
      BTNS: {
        PREVIEW: 'Preview',
        CREATE_CAMPAIGN: 'Create Campaign',
        SELECT_MEDIA: 'Select from media',
        CREATE_AUDIANCE: 'Create Audience',
      },
    },
    SIDE_NAV: {
      CLIENT_PROFILE: 'Client Profile',
      DASHBOARD: 'Dashboard',
      USERS_MANAGEMENT: 'Users Management',
      ADD_USER: 'Add User',
      VIEW_USERS: 'View Users',
      CLIENT_MANGEMENT: 'Clients Management',
      ADD_CLIENT: 'Add Client',
      VIEW_CLIENT: 'View Clients',
      COMPANY_PROFILE: 'Company Profile',
      CAMPAIGN_MANAGEMENT: 'Campaign Management',
      RECRUITMENT_ANALYTICS: 'Recruitment Analytics',
      AUDIENCES: 'Audiences',
      REPORTING: 'Reporting',
      LEARN_MORE: 'Learn More',
      APPLICATIONS: 'Applications',
      CONTACT_SUPPORT: 'Contact Support',
      TalentAnalytics: 'Talent Analytics',
    },
    HEADER: {
      EDIT_PROFILE: 'Edit Profile',
      PROFILE: 'Profile',
      MY_PROFILE: 'My Profile',
      CHANGE_PASSWORD: 'Change Password',
      EDIT_MY_PROFILE: 'Edit My Profile',
      SIGN_OUT: 'Sign Out',
      USERS: 'Users',
    },
    BTNS: {
      ADD_CLIENT: 'Add Client',
      UPLOAD: 'Upload',
      DELETE: 'Delete',
      CLOSE: 'Close',
      CONFIRM: 'Confirm',
      ADD_CONTENT: 'Add to Conetnt',
    },
    ANALYTICS: {
      SKILLS: 'Skills',
      JOBS: 'Job/Functions',
      iNTERESTS: 'Interests',
    },
    VALIDATIONS: {
      REQUIRED: 'This field is required',
      EMAIL: 'Invalid email address',
      MIN_LENGTH: 'this field length must be at least {{length}} characters long',
      MAX_LENGTH: 'this field length must be less than or equal to {{length}} characters long',
      PASSWORD_MATCH: 'Passwords much match',
      MAX: 'Should be smaller than or equal to {{max}}',
      MIN: 'Should be greater than or equal to {{min}}',
      SELECTION: 'Invalid selection',
      CONSECUTIVE_YEAR: 'Target should be greater than {{year}}',
    },
    TALENT_ANALYTICS: {
      TITLE: 'You are one step away from finding hidden Talent on Social Media!',
      DESC1:
        "Use the filters, keywords and interests to identify & find the right Talent pool for your jobs. Remember to think outside the box to reach enough people, as not everyone puts their job title! If your audience is too broad or too small, it won't reach enough users. Good numbers are between 5,000 & 50,000.",
      DESC2: 'You can then just save your audience and create your Job Ad.',
    },
    TALENT_ANALYTICS_LINKEDIN: {
      TITLE: 'Search your potential talent pool using the filters below and save your audience',
    },
  },
  NAME: 'name',
  AGE_FROM: 'age from',
  AGE_TO: 'age to',
  GENDER: 'gender',
  created_at: 'created at',
};
