import { ReplacePipe } from './../@core/pipes/replace.pipe';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Translation
import { TranslateModule } from '@ngx-translate/core';

// NgBootstrap
import {
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from './crud';
// Layout partials
import { ContextMenu2Component, ContextMenuComponent, SearchResultComponent } from './layout';
// General
// Errpr
import { ErrorComponent } from './error/error.component';
// Extra module
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Dropzone
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NgxSmartModalModule } from 'ngx-smart-modal';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ObjectKeysPipe } from './pipes/object-keys.pipe';

import { CurrencyMaskModule } from 'ng2-currency-mask';

import { SlimLoadingBarModule } from '@cime/ngx-slim-loading-bar';

import { NgxBootstrapSwitchModule } from 'ngx-bootstrap-switch';
import { NgxPaginationModule } from 'ngx-pagination';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ExpandDirective } from './directives/expand.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'xng-breadcrumb';

// shared components
import { HeaderComponent } from './components/header/header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule, MatNavList } from '@angular/material/list';
import { HideBrokenDirective } from './directives/hide-img-proken/hide-broken.directive';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UtilsService } from './services/utils/utils.service';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GadgetComponent } from './gadget/gadget.component';
import { ClickOutsideDirective } from './directives/clickoutside.directive';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://spark-api.b5digital.dk/api/',
  acceptedFiles: 'image/*',
  maxFilesize: 20,
  autoProcessQueue: false,
  addRemoveLinks: true,
  dictRemoveFile: '<i class="fa fa-times"></i>',
};

@NgModule({
  declarations: [
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    SearchResultComponent,
    DataTableComponent,
    ErrorComponent,
    ObjectKeysPipe,
    ExpandDirective,
    BreadcrumbComponent,
    HeaderComponent,
    SideNavComponent,
    HideBrokenDirective,
    FooterComponent,
    LoaderComponent,
    DatepickerComponent,
    InputErrorComponent,
    GadgetComponent,
    ReplacePipe,
    ClickOutsideDirective,
  ],
  exports: [
    // UtilsService,
    InputErrorComponent,
    DatepickerComponent,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    BreadcrumbComponent,
    AlertComponent,
    HeaderComponent,
    SideNavComponent,
    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    SearchResultComponent,
    GadgetComponent,
    ReplacePipe,

    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    // MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    // MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    // MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    // MatToolbarModule,
    MatButtonToggleModule,
    // MatSlideToggleModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,

    // external module
    //  DropzoneModule,
    TranslateModule,
    ErrorComponent,
    DataTableComponent,
    //NgxSmartModalModule,
    // SweetAlert2Module,
    NgbTypeaheadModule,
    NgbModule,
    ObjectKeysPipe,
    CurrencyMaskModule,
    SlimLoadingBarModule,
    // NgxBootstrapSwitchModule,
    SelectDropDownModule,
    FooterComponent,
    LoaderComponent,
    NgxDropzoneModule,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    // PerfectScrollbarModule,
    // InlineSVGModule,
    // angular material modules
    NgxDropzoneModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    // MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    // MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    // MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    // MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    // MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    // external module
    // DropzoneModule,
    TranslateModule.forChild(),
    SlimLoadingBarModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatFormFieldModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    // NgbTabsetModule,
    // NgbTooltipModule,
    NgbTypeaheadModule,
    // NgxSmartModalModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    CurrencyMaskModule,
    // NgxBootstrapSwitchModule,
    NgxPaginationModule,
    SelectDropDownModule,
  ],
  providers: [
    DatePipe,
    UtilsService,
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
  entryComponents: [ActionNotificationComponent, DeleteEntityDialogComponent],
})
export class SharedModule {}
