import { environment as Environment } from '../../../environments/environment';

export const API_URL = Environment.ApiURL || 'https://spark-api.b5digital.dk/api';
export const BASE_URL = Environment.BaseUrl || 'https://spark-api.b5digital.dk/';

/**
 * An Object that holds all the api endpoints of the backend
 * @type {Object}
 */
export const API = {
  API_URL: API_URL,
  me: `${API_URL}/me`,
  updateProfile: `${API_URL}/update-profile`,
  Authentication: {
    Login: `${API_URL}/auth`,
    verifyToken: `${API_URL}/me`,
    forgotPassword: `${API_URL}/forgot/password`,
    resetPassword: `${API_URL}/password/reset`,
    changePassword: `${API_URL}/auth/change-password`,
  },
  users: `${API_URL}/users`,
  roles: `${API_URL}/roles`,
  permissions: `${API_URL}/permissions`,
  tickets: `${API_URL}/tickets`,
  csrfCookie: `${API_URL}/sanctum/csrf-cookie`,
  lookups: `${API_URL}/lookups`,
};
