<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <kt-auth-notice></kt-auth-notice>
    <div class="main-card-signin d-md-flex wd-100p">
      <div class="wd-md-50p login d-none d-md-block page-signin-style p-5 text-white">
        <div class="my-auto authentication-pages">
          <div>
            <img src="/assets/media/logos/adison-logo.png" class="m-0 mb-4 wd-100p" alt="logo" />
            <h5 class="mb-4 tx-18 tx-bold">Welcome to Talent Cloud Media.</h5>
            <p class="mb-5">
              Talent Clound will never ask you for personal information such as contact details, bank details, or any
              other personal information aside from your username.
            </p>
          </div>
        </div>
      </div>
      <div class="p-5 wd-md-50p">
        <div class="main-signin-header">
          <h2>Reset Password?</h2>
          <h4>Please fill up the form to continue</h4>
          <form action="index.html" [formGroup]="registerForm" autocomplete="off">
            <div class="form-group">
              <label>Email</label
              ><input
                class="form-control"
                placeholder="{{ 'AUTH.LOGIN.EMAIL' | translate }}"
                type="email"
                formControlName="email"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('email', 'required')">
                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'email')">
                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'minlength')">
                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'maxlength')">
                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
              </mat-error>
            </div>
            <!-- pasword -->
            <div class="form-group">
              <label>Password</label
              ><input
                class="form-control"
                placeholder=""
                type="password"
                value=""
                formControlName="password"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('password', 'required')">
                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'email')">
                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'minlength')">
                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'maxlength')">
                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
              </mat-error>
            </div>
            <div class="form-group">
              <label>Password Confirmation</label
              ><input
                class="form-control"
                placeholder=""
                type="password"
                value=""
                formControlName="password_confirmation"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('password_confirmation', 'required')">
                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password_confirmation', 'email')">
                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password_confirmation', 'minlength')">
                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password_confirmation', 'maxlength')">
                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
              </mat-error>
            </div>
            <button
              (click)="submit()"
              [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
              class="btn btn-main-primary btn-block"
            >
              Reset Password
            </button>
          </form>
        </div>
        <div class="main-signin-footer mt-3 mg-t-5">
          <!-- <p><a class="tx-14" href="" href="javascript:;" routerLink="/auth/forgot-password">Forgot password?</a></p> -->
          <p>Already have an account? <a routerLink="/auth/login">Login</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
