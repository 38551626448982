// SERVICES
export { AuthService } from './_services';
export { AuthNoticeService } from './auth-notice/auth-notice.service';

// ACTIONS
export {
  Login,
  Logout,
  Register,
  UserRequested,
  UserLoaded,
  AuthActionTypes,
  AuthActions,
} from './_actions/auth.actions';
export {
  AllPermissionsRequested,
  AllPermissionsLoaded,
  PermissionActionTypes,
  PermissionActions,
} from './_actions/permission.actions';
export {
  RoleOnServerCreated,
  RoleCreated,
  RoleUpdated,
  RoleDeleted,
  // RolesPageRequested,
  // RolesPageLoaded,
  RolesPageCancelled,
  AllRolesLoaded,
  AllRolesRequested,
  RoleActionTypes,
  RoleActions,
} from './_actions/role.actions';

// EFFECTS
export { AuthEffects } from './_effects/auth.effects';
export { PermissionEffects } from './_effects/permission.effects';
export { RoleEffects } from './_effects/role.effects';

// REDUCERS
export { authReducer } from './_reducers/auth.reducers';
export { permissionsReducer } from './_reducers/permission.reducers';
export { rolesReducer } from './_reducers/role.reducers';

// SELECTORS
export {
  isLoggedIn,
  isLoggedOut,
  isUserLoaded,
  currentAuthToken,
  currentUser,
  currentUserRoleIds,
  currentUserPermissionsIds,
  currentUserPermissions,
  checkHasUserPermission,
  currentUserRole,
} from './_selectors/auth.selectors';
export {
  selectPermissionById,
  selectAllPermissions,
  selectAllPermissionsIds,
  allPermissionsLoaded,
} from './_selectors/permission.selectors';
export {
  selectRoleById,
  selectAllRoles,
  selectAllRolesIds,
  allRolesLoaded,
  selectLastCreatedRoleId,
  selectRolesPageLoading,
  selectRolesActionLoading,
  selectRolesShowInitWaitingMessage,
} from './_selectors/role.selectors';

// GUARDS
export { AuthGuard } from './_guards/auth.guard';
export { ModuleGuard } from './_guards/module.guard';
export { AdminGuard } from './_guards/admin.guard';
export { RoleGuard } from './_guards/role.guard';

// MODELS
export { User } from './_models/user.model';
export { Permission } from './_models/permission.model';
export { Role } from './_models/role.model';
export { AuthNotice } from './auth-notice/auth-notice.interface';
