import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';

const rollbarConfig = {
  accessToken: environment.RollbarAccessToken,
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: environment.production ? 'production' : 'development',
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.log(err);
    //this.rollbar.error(err.originalError || err);
    if (!location.hostname.includes('localhost')) {
      if (err.originalError || err.name != 'HttpErrorResponse') {
        console.error(err.originalError || err);
        this.rollbar.error(err.originalError || err);
      }
    } else {
      console.error(err.originalError || err);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
