// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../@core/reducers/';
import { environment } from '../../../../environments/environment';
import { Login, Logout, UserLoaded, UserRequested } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpHelperService } from 'app/@shared/services/httpHelper.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private auth: AuthService,
    private permissionsService: NgxPermissionsService,
    private httpHelperService: HttpHelperService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userToken = localStorage.getItem(environment.authTokenKey);
    if (userToken) {
      new Login({ authToken: userToken });
      localStorage.setItem(environment.authTokenKey, userToken);
      this.store.dispatch(new UserRequested());
      return this.auth.getUserByToken().pipe(
        map(
          (_user: any) => {
            const user = _user.data;
            if (user) {
              this.store.dispatch(new UserLoaded({ user }));
              if (user.permissions_list) {
                this.permissionsService.flushPermissions();
                this.permissionsService.loadPermissions(user.permissions_list.map((pm) => pm.name));
                return true;
              } else {
                return true;
              }
            } else {
              this.store.dispatch(new Logout());
              return false;
            }
          },
          (err) => {
            this.httpHelperService.handleError(err);
          }
        )
      );
    }
    // this.router.navigateByUrl('/auth/login');
    return of(false);

    // const userToken = localStorage.getItem(environment.authTokenKey);
    // if (!userToken || userToken == null) {
    //    this.router.navigateByUrl('auth/login');
    //     return of(false);

    // }
    // return of(true);
  }
}
