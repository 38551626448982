// Angular
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { AuthNoticeService } from 'app/@core/auth';
import { environment } from 'environments/environment';
// Layout
// Auth

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  headerLogo: string;
  appName: string = environment.AppName;

  /**
   * Component constructor
   *
   * @param el
   * @param render
   * @param layoutConfigService: LayoutConfigService
   * @param authNoticeService: authNoticeService
   * @param translationService: TranslationService
   * @param splashScreenService: SplashScreenService
   */
  constructor() {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  /* 	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	} */

  /* 	hideSplashScreen(){
		setTimeout(() => {
			this.splashScreenService.hide();
		}, 1000);
	} */
}
