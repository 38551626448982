import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../@core/reducers/';
import { currentUser, isLoggedIn } from '../_selectors/auth.selectors';
@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(isLoggedIn),
      map((isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigateByUrl('/pages/home');
          // this.router.navigateByUrl('/auth/login');
          return false;
        }

        return true;
      })
    );
  }
}
