import { AppState } from './@core/reducers/index';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from './@core/auth/_selectors/auth.selectors';
import { Subscription } from 'rxjs';
import * as Feather from 'feather-icons';

// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationService } from './@core/services/translation.service';
// Layout
// language list
import { locale as enLang } from './@core/config/i18n/en';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Login } from './@core/auth';
import { HttpHelperService } from '../app/@shared/services/httpHelper.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, OnChanges {
  // Public properties
  title = 'LEASO';
  loader: boolean;
  isLoggedIn;
  isHeaderPresented: string;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private titleService: Title,
    private store: Store<AppState>,
    private httpHelperService: HttpHelperService
  ) {
    // register translations
    this.titleService.setTitle(environment.AppName);
    // this.translationService.loadTranslations(enLang);
    this.translationService.loadTranslations(enLang);
    this.isLoggedIn = this.store.select(isLoggedIn).subscribe((x) => {
      this.isLoggedIn = x;
    });
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.listenToHeaderToggle();
    // if (localStorage.getItem(environment.authTokenKey)) {
    //   this.isHeaderPresented = "presented";
    //   this.isLoggedIn = true;
    // }
    // else {
    //   this.isLoggedIn = false;
    //   this.router.navigateByUrl("auth/login")

    //   console.log(this.isLoggedIn);
    // }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (localStorage.getItem(environment.authTokenKey)) {
      this.isHeaderPresented = 'presented';
      // this.isLoggedIn = true;
    }
  }

  ngAfterViewInit() {
    if (localStorage.getItem(environment.authTokenKey)) {
      this.isHeaderPresented = 'presented';
      this.isLoggedIn = true;
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  listenToHeaderToggle() {
    this.httpHelperService.isHeaderPresented$.subscribe((res) => {
      console.log(`listen on header change`);
      console.log(res);
      if (res == null) {
      } else {
        this.isHeaderPresented = res;
      }
    });
  }
}
