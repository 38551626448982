// Angular
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { tap, retryWhen, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AppState } from '../reducers';
import { HttpHelperService } from 'app/@shared/services/httpHelper.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private router: Router,
    private httpHelperService: HttpHelperService
  ) {}

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = (this.tokenExtractor.getToken() as string) || '';

    request = request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization:
          location.hostname == 'localhost' ? `Bearer ${localStorage.getItem(environment.authTokenKey)}` : '',
        'X-Requested-With': 'XMLHttpRequest',
        accept: 'application/json',
        'X-XSRF-TOKEN': location.hostname != 'localhost' ? token : '',
      },
    });

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status == 419 || error.status == 401) {
            //this.store.dispatch(new Logout());
            //localStorage.removeItem(environment.authTokenKey)
            window.localStorage.clear();
            this.httpHelperService.toggleHeader('notPresented');
            this.router.navigate(['/auth/login']);
            return throwError(error);
          }
          if (error.status == 422) {
            return throwError(error);
          }
          if (error.status == 500) {
            return throwError({
              error: {
                errors: {},
              },
            });
          }
          if (error.status === 0) {
            this.httpHelperService.handleError(error);
          }
        }
        return throwError(error);
      }),
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            //console.log('all looks good');
            //http response status code
            //console.log(event.status);
          }
        },
        (error) => {
          return;
          // http response status code
          //console.log('----response----');
          console.error('status code:');
          // tslint:disable-next-line:no-debugger
          console.error(error.status);
          console.error(error.message);
        }
      )
    );
  }
}
