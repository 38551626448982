<!-- <ngx-slim-loading-bar></ngx-slim-loading-bar> -->
<!-- Old sign in form  -->
<!-- end of old sign in form  -->
<div class="kt-login__body">
  <div class="kt-login__form">
    <kt-auth-notice></kt-auth-notice>
    <div class="main-card-signin d-md-flex wd-100p">
      <div class="wd-md-50p login d-none d-md-block page-signin-style p-5 text-white">
        <div class="my-auto authentication-pages">
          <div>
            <img src="/assets/media/logos/adison-logo.png" class="m-0 mb-4 wd-100p" alt="logo" />
            <h5 class="mb-4 tx-18 tx-bold">Welcome to your new Intelligent Social Recruiting Software.</h5>
            <p class="mb-5 tx-16">
              Please login to access your Recruitment Analytics and Ad creation dashboard.<br />
              Discover your potential Talent pools across platforms, create your Job Ad and publish it on social media,
              using our software.
            </p>
          </div>
        </div>
      </div>
      <div class="p-5 wd-md-50p">
        <div class="main-signin-header">
          <h2>Welcome back!</h2>
          <h4>Please sign in to continue</h4>
          <form action="index.html" [formGroup]="loginForm" autocomplete="off">
            <div class="form-group">
              <label>Email</label
              ><input
                class="form-control"
                placeholder="{{ 'AUTH.LOGIN.EMAIL' | translate }}"
                type="email"
                value="info@spruko.com"
                formControlName="email"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('email', 'required')">
                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'email')">
                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'minlength')">
                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'maxlength')">
                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
              </mat-error>
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                class="form-control"
                type="password"
                placeholder="{{ 'AUTH.LOGIN.PASSWORD' | translate }}"
                formControlName="password"
                autocomplete="off"
                value="sprukodemo"
              />
              <mat-error *ngIf="isControlHasError('password', 'required')">
                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'minlength')">
                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'maxlength')">
                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
              </mat-error>
            </div>
            <button
              (click)="submit()"
              [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
              class="btn btn-main-primary btn-block"
            >
              {{ 'AUTH.LOGIN.BUTTON' | translate }}
            </button>
          </form>
        </div>
        <div class="main-signin-footer mt-3 mg-t-5">
          <p><a class="tx-14" href="" href="javascript:;" routerLink="/auth/forgot-password">Forgot password?</a></p>
          <!-- <p>Don't have an account? <a href="page-signup.html">Create an Account</a></p> -->
        </div>
      </div>
    </div>
  </div>
</div>
