import { ROLES } from './../../constants/roles';
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../@core/reducers/';
import { currentUser } from '../_selectors/auth.selectors';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(currentUser),
      map((currentUser) => {
        if (currentUser && currentUser.role && currentUser.role.name == ROLES.USER) {
          return true;
        }
        this.router.navigateByUrl('/pages/home');
        return false;
      })
    );
  }
}
