/**
 * An Object that holds all the returned roles strings in the system
 * @type {Object}
 */
export const ROLES = {
  ADMIN: 'super_admin',
  EMPLOYEE: 'Employee',
  USER: 'user',
  CLIENT: 'client',
};
