import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHelperService } from 'app/@shared/services/httpHelper.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API } from './../../@core/constants/api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _addedUser: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public addedUser$: Observable<boolean> = this._addedUser.asObservable();
  public updatedLoggedInData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public analysisStatistics$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private httpHelperService: HttpHelperService) {
    //this.currentUser.next(JSON.parse(localStorage.getItem('user'))) ;
    this.getLoggedInClient().subscribe((data) => {
      this.currentUser.next(data.data);
    });
  }

  updateCurrentUser(user) {
    // debugger ;
    this.currentUser.next(user);
  }

  updateUpdatedLoggedInData(user) {
    this.updatedLoggedInData.next(user);
  }

  setUserHasBeenAdded(value: boolean) {
    this._addedUser.next(value);
  }

  getUserRoles() {
    return this.httpHelperService.get(`roles`);
  }

  editUser(id, payload) {
    payload.append('role', 'user');
    payload.append('type', 'user');
    payload.append('_method', 'PUT');
    console.log('payload', payload);
    return this.httpHelperService.post(`users/${id}`, payload);
  }

  editClient(id, payload) {
    console.log('payload', payload);
    // payload.append("role", 'client');
    // payload.append("type", 'client');

    // payload.append('_method','PUT');
    return this.httpHelperService.put(`users/${id}`, payload);
  }

  editFile(id, payload) {
    console.log('payload', payload);
    // payload.append("role", 'client');
    payload.append('type', 'client');

    payload.append('_method', 'PUT');
    return this.httpHelperService.post(`users/${id}`, payload);
  }

  addUser(payload) {
    payload.append('role', 'user');
    payload.append('type', 'user');
    // payload.append("campaign_limit", '5');
    return this.httpHelperService.post(`users/`, payload);
  }
  addClient(payload) {
    // payload.append("type", 'client');
    // console.log('payload', payload);
    return this.httpHelperService.post(`users/`, payload);
  }

  getUsers(role, filters = {}) {
    return this.httpHelperService.get(`users?role=${role}`, filters);
  }

  getUserDetails(id) {
    return this.httpHelperService.get(`users/${id}`);
  }

  removeProfilePicture(id) {
    this.httpHelperService.put(`users/${id}/removeProfilePicture`, {});
  }

  deleteUser(id) {
    return this.httpHelperService.delete(`users/${id}`);
  }

  downloadImage(url) {
    return this.httpHelperService.downloadFile(url);
  }

  disableUser(id) {
    return this.httpHelperService.get(`users/${id}/change-status`);
  }

  getLoggedInClient() {
    return this.httpHelperService.get(`users/me`);
  }

  // update logged in client only
  updateLoggedInClient(client) {
    // client.append('_method','PUT');
    // client.append("role", 'client');
    return this.httpHelperService.put(`users/me`, client);
  }

  updateCurrentClient(client) {
    client.append('_method', 'PUT');
    client.append('type', 'client');
    return this.httpHelperService.post(`users/me`, client);
  }

  getCountries() {
    return this.httpHelperService.get(`countries`);
  }

  getAnalysisStatic() {
    return this.httpHelperService.get(`analysis/statistics`).pipe(
      tap((res) => {
        !!res?.data && this.analysisStatistics$.next(res?.data);
      })
    );
  }
}
